import { useContext, useEffect, useRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { Loader } from '../../../../../components/Loader';
import { FilesContext } from '../../../files-common';
import { Card } from './Card';
import { styles } from './styles';

export const ITEM_WIDTH = 215;
export const ITEM_HEIGHT = 190;

const getItemsAmount = (width) => {
  return Math.max(Math.floor(width / ITEM_WIDTH), 1);
};

const useStyles = makeStyles(styles);

export const RecentlyOpened = () => {
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const classes = useStyles();
  const {
    isFetched,
    isFetching,
    files,
    filter,
    applyFilter
  } = useContext(FilesContext);

  useEffect(() => {
    if (width) {
      applyFilter({ per_page: getItemsAmount(width) });
    }
  }, [ width ]);

  useEffect(() => {
    const maxItemsAmount = getItemsAmount(width);

    if (!isFetching && maxItemsAmount > files.length) {
      if (filter.last_page > filter.page) {
        applyFilter({ page: filter.page + 1 });
      }
    }
  }, [ isFetching, width, files.length ]);

  return (
    <div ref={rootRef} className={classes.root}>
      <Typography variant="h3" gutterBottom>Recently opened</Typography>

      <div className={classes.filesRow}>
        {!isFetched && <Loader className={classes.loader} />}

        {isFetched && !files.length &&
          <Typography align="center" className={classes.noFilesMessage}>
            No previously opened files
          </Typography>
        }

        {files.map((file) => (
          <div key={file.id} className={classes.file}>
            <Card file={file} />
          </div>
        ))}
      </div>
    </div>
  );
};
