import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteGridLoader } from '../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../components/Loader';
import {
  PersonalInjuriesContext
} from '../../../../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import { ScrollbarsCustom } from '../../../../../../../components/ScrollbarsCustom';
import { Card } from './Card';

export const Grid = ({ autoHeight, hiddenColumns, Cell = Card, CellProps = {}, ...props }) => {
  const {
    isFetching,
    isFetched,
    personalInjuries,
    meta,
    loadNextPage
  } = useContext(PersonalInjuriesContext);
  const scrollElementRef = useRef();

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Box p={2}>
        <Loader loading={!isFetched} render={
          () => !personalInjuries.length ? (
            <Typography align="center">No tickets found</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteGridLoader
                autoSize
                cellWidth={380}
                items={personalInjuries}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                CellComponent={Cell}
                CellProps={{ hiddenColumns, ...CellProps }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
