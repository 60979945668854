/* eslint-disable max-len */

import { Typography, Link } from '@material-ui/core';
import { ServicePageLayout } from '../shared/ServicePageLayout';
import { useServicePageStyles } from '../shared/styles';

export const CookiePolicy = () => {
  const classes = useServicePageStyles();

  return (
    <ServicePageLayout title="Cookie Policy">
      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          1. Introduction
        </Typography>

        <Typography paragraph>
          This Cookie Policy explains how CaseArk.com ("Website"), operated by ITTECHADMIN ("Company," "we," "us," "our"), uses cookies and similar tracking technologies. By using our website, you agree to the use of cookies as described in this policy.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          2. What Are Cookies?
        </Typography>

        <Typography paragraph>
          Cookies are small text files stored on your device when you visit a website. They help improve user experience, enhance security, and provide insights into how our services are used.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          3. Types of Cookies We Use
        </Typography>

        <Typography paragraph>
          CaseArk uses the following types of cookies:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Essential Cookies:</strong> Required for the website to function properly (e.g., authentication, security, session management). These cannot be disabled.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Performance Cookies:</strong> Collect information about how users interact with the website (e.g., page visits, errors) to improve functionality.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Functional Cookies:</strong> Store user preferences and settings (e.g., language, theme customization).
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Analytics Cookies:</strong> Help us track visitor behavior and website performance through tools like Google Analytics.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Advertising Cookies:</strong> Used to deliver relevant ads and measure the effectiveness of marketing campaigns.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          4. How We Use Cookies
        </Typography>

        <Typography paragraph>
          We use cookies to:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Ensure website security and account authentication.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Improve website functionality and user experience.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Monitor and analyze website traffic and usage trends.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Store user preferences for a personalized experience.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Deliver relevant content and advertising.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          5. Third-Party Cookies
        </Typography>

        <Typography paragraph>
          Some cookies are set by third-party services we use, such as:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Google Analytics</strong> (for traffic analysis and performance monitoring).
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Payment Processors</strong> (if applicable, for handling transactions securely).
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Advertising Partners</strong> (for remarketing and targeted advertising, if enabled).
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          6. Managing Cookies
        </Typography>

        <Typography paragraph>
          You can control or disable cookies by:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Adjusting your browser settings to block or delete cookies.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Using the "cookie preferences" tool on our website (if available).
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Opting out of Google Analytics tracking by installing the {' '}
            <Link
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics Opt-out Browser Add-on
            </Link>.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          7. California and GDPR Compliance
        </Typography>

        <Typography paragraph>
          For users in California (CalOPPA, CCPA) and the European Union (GDPR):
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            You have the right to <strong>opt-out of non-essential cookies</strong>.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We do not sell personal data collected through cookies.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We provide a "Do Not Track" (DNT) option where applicable.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users can request the deletion of stored cookie data by contacting us.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          8. Disclaimer of Liability
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>ITTECHADMIN is not responsible</strong> for any data loss, unauthorized access, or misuse of information collected through cookies.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We do not guarantee the accuracy, reliability, or security of third-party cookies used on our website.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users assume all risks related to enabling or disabling cookies on their devices.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          9. Changes to This Cookie Policy
        </Typography>

        <Typography paragraph>
          We may update this policy periodically. Any changes will be posted on this page, and significant updates may be communicated through a notice on our website.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          10. Contact Information
        </Typography>

        <Typography paragraph>
          For questions about this Cookie Policy or your cookie preferences, please contact us at:
        </Typography>

        <Typography className={classes.companyInfo}>
          <strong>ITTECHADMIN</strong><br />
          Email: info@ittechadmin.com<br />
          Phone: (818) 647-1937<br />
          Address: 4812 Topanga Can Blvd, Woodland Hills, CA 91364
        </Typography>
      </section>
    </ServicePageLayout>
  );
};
