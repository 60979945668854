import { Dialog } from '@material-ui/core';
import { MapProvider } from './MapProvider';
import { ModalContent } from './ModalContent';

export const MapModal = ({
  payload: {
    address,
    lat,
    lng,
    locationName,
    addresses,
    initialAddressKey
  },
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  return (
    <Dialog fullScreen {...DialogProps}>
      <MapProvider
        address={address}
        lat={lat}
        lng={lng}
        locationName={locationName}
        addresses={addresses}
        initialAddressKey={initialAddressKey}
        onError={handleModalReject}
      >
        <ModalContent
          onModalResolve={handleModalResolve}
          onModalReject={handleModalReject}
        />
      </MapProvider>
    </Dialog>
  );
};
