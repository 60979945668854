import { Box } from '@material-ui/core';
import { Page } from '../../../components/Page';
import { LandingWrapper } from '../../LandingPage';
import { FormItemComponent } from './FormItemComponent';
import { FormProvider } from './FormProvider';

export const GuestMedicalProfile = () => {
  return (
    <FormProvider>
      <LandingWrapper>
        <Box display="flex" width="100%" height="100%">
          <Page>
            <FormItemComponent />
          </Page>
        </Box>
      </LandingWrapper>
    </FormProvider>
  );
};
