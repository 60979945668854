import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Grid, makeStyles, MenuItem, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { requestDemo } from '../../api/demo-request';
import { TextField, PhoneField, CurrencyField } from '../../components/FormField';
import { Loader } from '../../components/Loader';
import { styles } from '../Auth/Login/styles';
import FullLogoSVG from '../Dashboard/Layout/Sidebar/SidebarContent/logo-full.svg?react';
import { LandingWrapper } from '../LandingPage';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

const useStyles = makeStyles(styles);

export const DemoRequest = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return requestDemo(values).then((data) => {
      enqueueSnackbar('Request for demo access was sent', { variant: 'success' });
      history.push('/');
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Request was not sent', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <LandingWrapper>
          <Paper className={classes.paper}>
            <div className={classes.paper__header}>
              <FullLogoSVG className={classes.paper__icon} />

              <Typography variant="h2" color="inherit" className={classes.paper__title}>
                Request a Demo
              </Typography>
            </div>

            <form noValidate className={classes.paper__header} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    name="first_name"
                    label="First Name"
                    placeholder="Enter first name..."
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="last_name"
                    label="Last Name"
                    placeholder="Enter last name..."
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    name="email"
                    type="email"
                    label="Business Email Address"
                    placeholder="Enter email..."
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="company_name"
                    label="Company Name"
                    placeholder="Enter company name..."
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    name="industry"
                    label="Industry"
                    placeholder="Enter first name..."
                    className={classes.input}
                  >
                    <MenuItem value="worker_compensation_law">
                      Worker Compensation Law
                    </MenuItem>

                    <MenuItem value="personal_injury_law">
                      Personal Injury Law
                    </MenuItem>

                    <MenuItem value="health_care">
                      Health Care
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <PhoneField
                    name="phone_number"
                    label="Phone Number"
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    name="number_of_employees"
                    label="Number of Employees"
                    className={classes.input}
                  >
                    <MenuItem value="small">
                      Small (&lt; 10 workers)
                    </MenuItem>

                    <MenuItem value="medium">
                      Medium (10 - 100 workers)
                    </MenuItem>

                    <MenuItem value="large">
                      Large (&gt; 100 workers)
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="title"
                    label="Title"
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CurrencyField
                    name="company_revenue"
                    label="Company Revenue"
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Loader surface fullWidth loading={isSubmitting} render={
                    () => (
                      <Button
                        fullWidth
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Send request
                      </Button>
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </LandingWrapper>
      )}
    </Formik>
  );
};
