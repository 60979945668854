import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required('First name is required'),
  last_name: Yup.string().nullable().required('Last name is required'),
  phone: Yup.string().nullable().required('Phone number is required'),
  email: Yup.string().nullable().email('Invalid email').required('Email is required'),
  mailing_address: Yup.string().nullable(),
  description_of_damage: Yup.string().nullable(),
  description: Yup.string().nullable(),
  incident_type: Yup.string().nullable(),
  incident_date: Yup.date().nullable(),
  address: Yup.string().nullable(),
  police_report_filed: Yup.string().nullable(),
  witnesses: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().nullable().required(),
      last_name: Yup.string().nullable().required(),
      phone: Yup.string().nullable().required()
    })
  ).nullable(),
  has_car_insurance: Yup.string().nullable(),
  has_medical_insurance: Yup.string().nullable(),
  preferred_contact: Yup.object().shape({
    date: Yup.date().nullable(),
    time_from: Yup.string().nullable(),
    time_to: Yup.string().nullable()
  })
});
