import { truncate } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ piRequest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>
        {truncate(piRequest.description || '-', { length: 240 })}
      </Typography>
    </div>
  );
};
