import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { sendPersonalInjury } from '../../api/personal-injury-requests';
import { LandingWrapper } from '../LandingPage';
import { Form } from './Form';
import { FormHeader } from './FormHeader';
import { validationSchema } from './validationSchema';
import { initialValues } from './initialValues';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  form: {
    paddingTop: theme.spacing(3)
  }
}));

export const PersonalInjuryForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    sendPersonalInjury(values).then(() => {
      enqueueSnackbar('Form submitted successfully', { variant: 'success' });
      history.push('/');
    }).catch(() => {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <LandingWrapper>
      <Paper className={classes.root}>
        <FormHeader
          form={{}}
          type="Personal Injury Form"
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Form />
            </form>
          )}
        </Formik>
      </Paper>
    </LandingWrapper>
  );
};
