import { useRef, useContext, useEffect } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { Sticky } from '../../../../../components/Sticky';
import { TrashContext } from '../TrashProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  name: 300,
  size: 90,
  date: 160,
  action: 64
};

export const List = () => {
  const {
    files,
    isFetched,
    isFetching,
    toggleAllFilesSelection,
    allFilesIsSelected,
    selectedFilesIDs,
    loadNextPage,
    filter,

    ...trashContext
  } = useContext(TrashContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    trashContext.resetFiles();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <Box py={2} pl={4} pr={3}>
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No files found</Typography>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={files}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
