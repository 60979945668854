import { createContext, useRef, useContext, useEffect, useReducer } from 'react';
import { merge, omit, isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemoEffect, usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as deletedFilesApi from '../../../../../api/files/deleted-files';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { FilesFilterContext } from '../../../files-common';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const TrashContext = createContext(null);

export const TrashProvider = ({
  ownerType,
  ownerID,
  children
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, {
    filter: {
      owner_type: ownerType,
      owner_id: ownerID
    }
  }));
  const { isFetched, isFetching, filter, pagination } = state;
  const {
    filter: commonFilter,
    selectedFilesIDs,
    addFilesToSelected,
    toggleFileSelection,
    deleteFilesFromSelected
  } = useContext(FilesFilterContext);
  const files = useRef(state.files);
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchFiles = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FILES_REQUEST });

    deletedFilesApi.fetchFiles({
      params: ({
        ...filter,
        ...newFilter
      }),
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FILES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFiles({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetFiles = (newFilter) => {
    dispatch({ type: types.RESET_FILES, payload: newFilter });
    deleteFilesFromSelected(files.current.map(({ id }) => id));
    fetchFiles({ page: 1, ...newFilter });
  };

  const deleteFiles = (filesIDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        deletedFilesApi.deleteFiles({
          params: {
            files: filesIDs,
            file_ids: filesIDs,
            owner_type: ownerType,
            owner_id: ownerID
          }
        }).then(() => {
          dispatch({ type: types.DELETE_FILES, payload: filesIDs });
          enqueueSnackbar(`${filesIDs.length} file successfully deleted`, { variant: 'success' });
        });
      }
    });
  };

  const emptyTrash = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        deletedFilesApi.emptyTrash({
          params: {
            owner_type: ownerType,
            owner_id: ownerID
          }
        }).then(() => {
          dispatch({ type: types.DELETE_ALL_FILES });

          enqueueSnackbar('All files successfully deleted', {
            variant: 'success'
          });
        });
      }
    });
  };

  const restoreFiles = (filesIDs) => {
    return new Promise(((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          deletedFilesApi.restoreFiles(filesIDs).then(() => {
            dispatch({ type: types.DELETE_FILES, payload: filesIDs });

            enqueueSnackbar(`${filesIDs.length} file successfully restored`, {
              variant: 'success'
            });

            resolve(filesIDs);
          });
        }
      });
    }));
  };

  const allFilesIsSelected = () => {
    return selectedFilesIDs.filter((id) => state.files.find((file) => file.id === id)).length === state.files.length;
  };

  const toggleAllFilesSelection = () => {
    const filesIDs = state.files.map(({ id }) => id);

    allFilesIsSelected() ? deleteFilesFromSelected(filesIDs) : addFilesToSelected(filesIDs);
  };

  const providerValue = {
    isFetched,
    isFetching,
    files: state.files,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetFiles,
    fetchFiles,
    loadNextPage,
    deleteFiles,
    restoreFiles,
    emptyTrash,
    selectedFilesIDs,
    deleteFilesFromSelected,
    allFilesIsSelected,
    toggleFileSelection,
    toggleAllFilesSelection
  };

  useEffect(() => {
    files.current = state.files;
  }, [ state.files ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
      deleteFilesFromSelected(files.current.map(({ id }) => id));
    };
  }, []);

  useMemoEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(commonFilter);
    }
  }, [ { ...omit(filter, [ 'page' ]), ...commonFilter } ]);

  useMemoEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFiles(newFilter);
    }
  }, [ prevFilter, omit(filter, [ 'page' ]) ]);

  return (
    <TrashContext.Provider value={providerValue}>
      {children}
    </TrashContext.Provider>
  );
};
