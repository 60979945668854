import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { fieldsKeysMap, filterLabels } from '../../filterKeysMap';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow noWrap flexBasis={columnsWidths.search}>
        {filterLabels[fieldsKeysMap.search]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        {filterLabels[fieldsKeysMap.status]}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
