import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, Box, Paper, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LandingWrapper } from '../LandingPage';
import { useServicePageStyles } from './styles';

export const ServicePageLayout = ({
  title,
  effectiveDate = '03/04/2025',
  children
}) => {
  const classes = useServicePageStyles();

  return (
    <LandingWrapper>
      <Container maxWidth="md">
        <Box mb={2}>
          <Link
            to="/"
            underline="always"
            variant="subtitle1"
            className={classes.backLink}
            component={RouterLink}
          >
            <ArrowBackIcon /> Back to home page
          </Link>
        </Box>

        <Paper className={classes.root}>
          <Typography
            variant="h1"
            className={classes.title}
          >
            {title}
          </Typography>

          <Typography variant="subtitle1" align="center" className={classes.effectiveDate}>
            Effective Date: {effectiveDate}
          </Typography>

          {children}
        </Paper>
      </Container>
    </LandingWrapper>
  );
};
