import { Autocomplete } from '../../FormField';
import { statusesOptions } from './statusesOptions';

const getOptionLabel = (option) => {
  return option?.label || statusesOptions.find(({ value }) => option === value)?.label;
};

export const PersonalInjuryStatusesSelect = (props) => {
  return (
    <Autocomplete
      name="status"
      label="Status"
      placeholder="Select status..."
      options={statusesOptions}
      getOptionLabel={getOptionLabel}

      {...props}
    />
  );
};
