import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { merge } from 'lodash';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import * as emailsApi from '../../../../api/emails';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { folderNamesMap } from './folderNamesMap';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const AccountsContext = createContext();

const folderToMap = {
  [folderNamesMap.inbox]: 'unseen',
  [folderNamesMap.junk]: 'junk',
  [folderNamesMap.drafts]: 'drafts'
};

export const AccountsProvider = ({ disableCounters = false, children, initialState: initialStateProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const [ activeAccountID, setActiveAccountID ] = useState(null);
  const { counters, accounts, filter } = state;
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const counterFetch = useRef({});
  const cancelFetch = useRef(() => {});
  const intervalRef = useRef();
  const [ countersFetchedStatuses, setCountersFetchedStatuses ] = useState({});

  const fetchAccounts = () => {
    cancelFetch.current();
    dispatch({ type: types.FETCH_ACCOUNTS_REQUEST });

    emailsApi.getEmailConfigList({
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((accounts) => {
      dispatch({ type: types.FETCH_ACCOUNTS_SUCCESS, payload: accounts });
    });
  };

  const fetchCounters = (id) => {
    setCountersFetchedStatuses({ ...countersFetchedStatuses, [id]: true });

    emailsApi.getCountUnseen(id, {
      cancelToken: new api.CancelToken((cancel) => counterFetch.current[id] = cancel)
    }).then((data) => {
      setCountersFetchedStatuses({ ...countersFetchedStatuses, [id]: false });
      !!data?.message
        ? dispatch({ type: types.SET_COUNTERS, payload: [ id, {} ] })
        : dispatch({ type: types.SET_COUNTERS, payload: [ id, data ] });
    }).catch((error) => {
      dispatch({ type: types.SET_COUNTERS_ERROR, payload: error });
    });
  };

  const setCounters = (length = 1) => {
    const newCounters = {
      ...counters[activeAccountID],
      unseen: ((counters[activeAccountID]?.unseen + length) > 0)
        ? counters[activeAccountID]?.unseen - length < 0 ? 0 : counters[activeAccountID]?.unseen - length
        : 0
    };

    dispatch({ type: types.SET_COUNTERS, payload: [ activeAccountID, newCounters ] });
  };

  const updateAccount = (data) => {
    dispatch({ type: types.UPDATE_ACCOUNT, payload: data });
  };

  const resetAccounts = () => {
    dispatch({ type: types.RESET_ACCOUNTS });

    fetchAccounts();
  };

  const deleteAccount = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        emailsApi.deleteEmailConfig(id).then(() => {
          dispatch({ type: types.DELETE_ACCOUNT, payload: id });
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Account is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    ...state,
    accounts,
    counters,
    filter,
    folderToMap,
    activeAccountID,

    // functions
    setActiveAccountID,
    setCounters,
    fetchCounters,
    fetchAccounts,
    updateAccount,
    resetAccounts,
    deleteAccount
  };

  useEffect(() => {
    !disableCounters && accounts.forEach((account) => {
      fetchCounters(account.id);
    });
  }, [ accounts ]);

  useEffect(() => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      accounts.forEach((account) => {
        if (countersFetchedStatuses[account.id]) {
          setCountersFetchedStatuses({ ...countersFetchedStatuses, [account.id]: false });

          if (!counters[account.id]) {
            counterFetch.current[account.id]();
            enqueueSnackbar(
              `Unfortunately the mailbox '${account.email}' is not responding`,
              { variant: 'warning' }
            );
          }
        }
      });
    }, 20 * 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [ countersFetchedStatuses ]);


  useEffect(() => {
    resetAccounts();

    return () => {
      accounts.forEach((account) => {
        setCountersFetchedStatuses({ ...countersFetchedStatuses, [account.id]: false });
        counterFetch.current[account.id]();
      });

      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // Remove the # character
    const code = params.get('code');
    const name = window.sessionStorage.getItem('emailAccountName');

    if (!code || !name) {
      return;
    }

    window.history.replaceState(
      null,
      document.title,
      window.location.pathname
    );

    window.sessionStorage.removeItem('emailAccountName');

    return emailsApi.connectEmailProvider({
      name,
      code,
      provider: 'microsoft'
    }).then(({ data }) => {
      enqueueSnackbar('Email account created', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors?.username) {
        enqueueSnackbar('This account is already connected to another user', { variant: 'error' });
      } else {
        enqueueSnackbar('Email account not added', { variant: 'error' });
      }
    });
  }, []);

  return (
    <AccountsContext.Provider value={providerValue}>
      {children}
    </AccountsContext.Provider>
  );
};
