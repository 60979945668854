import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit, debounce } from 'lodash';
import { Box, makeStyles } from '@material-ui/core';
import { saveFilter } from '../../../../../store/lastFilters';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { TextField, KeyboardDatePicker, OrderBy } from '../../../../../components/FormField';
import { IconComponent } from '../../../../../components/saved-filters';
import { TagsSelect } from '../../../../../components/Tags/TagsSelect';
import { FilesFilterContext, orderByOptions } from '../../FilesFilterContext';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { transformRelationsForFilterToOptions } from './utils';
import { CardContent } from './CardContent';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MODAL_WIDTH = 760;

export const initialValues = {
  order_by: orderByOptions[0]
};

export const FiltersBar = ({ hiddenFields = [], filterKey }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { filter, relationsForFilter, applyFilter, setRelationsForFilter } = useContext(FilesFilterContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter(({ ...filter, [name]: value }));
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleSelectChange = (name) => (option) => {
    applyFilter(({ ...filter, [name]: option?.value }));
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data }));
  };

  const handleTagsChange = (tags) => {
    applyFilter({ ...filter, tags: tags?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, tags }));
  };

  const handleFoldersChange = (folders) => {
    applyFilter({ ...filter, folders: folders?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, folders }));
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ ...filter, [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ ...filter, order_direction: orderDirection });
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(filter));

    applyFilter(omit({
      ...filter,

      tags: filter?.tags?.map((tag) => tag?.value || tag?.id),
      order_by: filter?.order_by?.value
    }, hiddenFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      className={classes.root}
      ToolbarProps={{ className: classes.toolbar }}
      filterKey={filterKey}
      initialValues={initialValues}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      hiddenFields={hiddenFields}
      onReset={() => {
        applySavedFilter({
          ...filter,
          order_by: orderByOptions.find(({ value }) => value === filter.order_by)
        });
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.originalFilename,
          label: filterFieldsLabels[filterFieldsMap.originalFilename],
          field: <TextField
            name="original_filename"
            label="Search"
            placeholder="Search..."
            value={filter.original_filename}
            onChange={handleFieldChange('original_filename')}
          />
        },
        {
          fieldKey: filterFieldsMap.tags,
          label: filterFieldsLabels[filterFieldsMap.tags],
          field: <TagsSelect
            multiple
            name="tags"
            label="Tags"
            placeholder="Search..."
            params={{ ...filter, only_with_files: 1 }}
            onChange={handleTagsChange}
          />
        },
        {
          fieldKey: filterFieldsMap.folders,
          label: filterFieldsLabels[filterFieldsMap.folders],
          field: <TagsSelect
            multiple
            name="folders"
            label="Folders"
            placeholder="Search..."
            params={{ ...filter, is_folder: 1, only_with_files: 1 }}
            onChange={handleFoldersChange}
          />
        },
        {
          fieldKey: filterFieldsMap.updatedAfter,
          label: filterFieldsLabels[filterFieldsMap.updatedAfter],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="updated_after"
                label="Updated after"
                onChange={handleDatePickerChange('updated_after')}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.updatedBefore,
          label: filterFieldsLabels[filterFieldsMap.updatedBefore],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="updated_before"
                label="Updated before"
                onChange={handleDatePickerChange('updated_before')}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: <OrderBy
            isSearchable={false}
            name="order_by"
            value={orderByOptions.find(({ value }) => value === filter.order_by)}
            options={orderByOptions}
            orderDirection={filter.order_direction}
            onOptionChange={handleSelectChange('order_by')}
            onOrderDirectionChange={toggleOrderDirection}
          />
        }
      ]}
    />
  );
};
