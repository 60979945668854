import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../helpers/createReduxReducer';
import * as types from './types';

const initialState = {
  filesLastGlobalAction: null,
  tasksLastGlobalAction: null,
  commentsLastGlobalAction: null,
  companiesLastGlobalAction: null,
  companiesTypesLastGlobalAction: null,
  officesLastGlobalAction: null,
  casesLastGlobalAction: null,
  tracksLastGlobalAction: null,
  usersLastGlobalAction: null,
  invoicesLastGlobalAction: null,
  incomesLastGlobalAction: null,
  expensesLastGlobalAction: null,
  paymentsLastGlobalAction: null,
  categoriesLastGlobalAction: null,
  cptCodesLastGlobalAction: null,
  schedulesLastGlobalAction: null,
  medicalProfileLastGlobalAction: null,
  historyLastGlobalAction: null
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [types.SET_FILES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, filesLastGlobalAction: payload };
  },

  [types.SET_APPOINTMENTS_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, appointmentsLastGlobalAction: payload };
  },

  [types.SET_TAGS_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, tagsLastGlobalAction: payload };
  },

  [types.SET_TASKS_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, tasksLastGlobalAction: payload };
  },

  [types.SET_COMMENTS_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, commentsLastGlobalAction: payload };
  },

  [types.SET_COMPANIES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, companiesLastGlobalAction: payload };
  },

  [types.SET_COMPANIES_TYPES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, companiesTypesLastGlobalAction: payload };
  },

  [types.SET_OFFICES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, officesLastGlobalAction: payload };
  },

  [types.SET_CASES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, casesLastGlobalAction: payload };
  },

  [types.SET_TRACKS_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, tracksLastGlobalAction: payload };
  },

  [types.SET_USERS_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, usersLastGlobalAction: payload };
  },

  [types.SET_INVOICES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, invoicesLastGlobalAction: payload };
  },

  [types.SET_INCOMES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, incomesLastGlobalAction: payload };
  },

  [types.SET_EXPENSES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, expensesLastGlobalAction: payload };
  },

  [types.SET_PAYMENTS_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, paymentsLastGlobalAction: payload };
  },

  [types.SET_CATEGORIES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, categoriesLastGlobalAction: payload };
  },

  [types.SET_CPT_CODES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, cptCodesLastGlobalAction: payload };
  },

  [types.SET_SCHEDULES_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, schedulesLastGlobalAction: payload };
  },

  [types.SET_MEDICAL_PROFILE_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, medicalProfileLastGlobalAction: payload };
  },

  [types.SET_HISTORY_LAST_GLOBAL_ACTION]: (state, payload) => {
    return { ...state, historyLastGlobalAction: payload };
  }
});
