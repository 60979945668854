export const styles = ({ spacing, palette, typography }) => ({
  mailboxControl: {
    marginRight: spacing(6.25)
  },

  mailboxControlLabel: {
    fontWeight: typography.fontWeightMedium,

    '& svg': {
      display: 'inline-block',
      marginRight: spacing(1.25),
      verticalAlign: 'middle'
    }
  },

  otherFields: {
    marginTop: spacing(5)
  },

  formLegend: {
    fontWeight: 500,
    color: palette.grey[600]
  },

  googleSignInButton: {
    marginTop: spacing()
  },

  alert: {
    marginTop: spacing()
  }
});
