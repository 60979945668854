import { useContext } from 'react';
import { isEqual } from 'lodash';
import GoogleMap from 'google-map-react';
import LocationSearchingIcon from '@material-ui/icons/LocationSearchingOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Loader } from '../Loader';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../Modal';
import { Tooltip } from '../Tooltip';
import { MapProviderContext } from './MapProvider';
import { Marker } from './Marker';
import { RouteInfo } from './RouteInfo';

export const ModalContent = ({ onModalResolve, onModalReject }) => {
  const {
    distance,
    duration,
    address,
    locationName,
    googleMapRef,
    location,
    currentLocation,
    currentAddress,
    center,
    addresses,
    handleGoogleApiLoaded,
    goToLocation,
    handleMapChange,
    onSelectedAddressChange,
    onAddressChange,
    onLocationChange
  } = useContext(MapProviderContext);

  return (
    <ModalContainer>
      <ModalHeader icon={<LocationOnOutlinedIcon />} onClose={onModalReject}>
        {address}

        <ModalHeaderActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onModalResolve(location)}
          >
            Save
          </Button>

          {!isEqual(location, center) &&
            <Tooltip title="Go to address">
              <IconButton color="primary" onClick={goToLocation}>
                <LocationSearchingIcon />
              </IconButton>
            </Tooltip>
          }
        </ModalHeaderActions>
      </ModalHeader>

      <ModalBody disablePaddings disableScrollbars>
        {distance && duration &&
          <RouteInfo />
        }

        <Loader loading={!location || !center} p={2} render={
          () => (
            <GoogleMap
              yesIWantToUseGoogleMapApiInternals
              ref={googleMapRef}
              bootstrapURLKeys={{ key: import.meta.env.PUBLIC_GOOGLE_API_KEY }}
              defaultZoom={12}
              center={center}
              onChange={handleMapChange}
              onGoogleApiLoaded={handleGoogleApiLoaded}
              onClick={(event) => {
                const newLocation = {
                  lat: event.lat,
                  lng: event.lng
                };

                onAddressChange(null);
                onLocationChange(newLocation);
              }}
            >
              <Marker {...location} title={locationName} subtitle={address} />
              <Marker {...currentLocation} title="My current location" subtitle={currentAddress} />

              {addresses?.map(({ key, location, locationName, address }) => (
                <Marker
                  {...location}

                  key={key}
                  title={locationName}
                  subtitle={address}
                  onClick={() => {
                    onSelectedAddressChange(key);
                    onAddressChange(address);
                  }}
                />
              ))}
            </GoogleMap>
          )}
        />
      </ModalBody>
    </ModalContainer>
  );
};
