import { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { Tooltip } from '../../../../../../components/Tooltip';

export const ActionsBar = ({ isMobile, filterOpenButton }) => {
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <PageHeader>
      <PageHeaderTitle>
        Personal Injury Requests
      </PageHeaderTitle>

      <PageHeaderActions disableSpacing endPosition>
        {!isMobile ? (
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
