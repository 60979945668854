export const filterFieldsMap = {
  originalFilename: 'original_filename',
  updatedAfter: 'updated_after',
  updatedBefore: 'updated_before',
  cases: 'cases',
  tasks: 'tasks',
  orderBy: 'order_by',
  tags: 'tags',
  folders: 'folders'
};

export const filterFieldsLabels = {
  [filterFieldsMap.originalFilename]: 'Search',
  [filterFieldsMap.updatedAfter]: 'Updated after',
  [filterFieldsMap.updatedBefore]: 'Updated before',
  [filterFieldsMap.cases]: 'Cases',
  [filterFieldsMap.tasks]: 'Tasks',
  [filterFieldsMap.orderBy]: 'Order By',
  [filterFieldsMap.tags]: 'Tags',
  [filterFieldsMap.folders]: 'Folders'
};
