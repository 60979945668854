import { Grid } from '@material-ui/core';
import { PersonalInjuryStatusesSelect } from '../PersonalInjuryStatusesSelect';

export const PersonalInjuryForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PersonalInjuryStatusesSelect
          disableClearable
          name="status"
          label="Status"
        />
      </Grid>
    </Grid>
  );
};
