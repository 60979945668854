import { FormControlLabel } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { workerRoles } from '../../../dataMaps';
import { UsersSelect } from '../../users';
import { Checkbox, CompaniesAutocomplete, KeyboardDatePicker } from '../../FormField';
import { fieldsMap } from './fieldsMap';

export const TimeReportForm = ({ hiddenFields = [] }) => {
  const { values } = useFormikContext();
  const isAdmin = hasRole(rolesMap.admin);

  return (
    <>
      {isAdmin && (
        <CompaniesAutocomplete
          required
          name="company_id"
          label="Company"
          margin="dense"
        />
      )}

      <FormControlLabel
        label="All users"
        control={<Checkbox name="is_all_users" />}
      />

      {!hiddenFields.includes(fieldsMap.user) && !values.is_all_users && (
        <UsersSelect
          required
          name="user_id"
          label="User"
          margin="dense"
          params={{
            is_active: 1,
            is_confirmed: 1,
            company_id: values?.company_id,
            roles: workerRoles
          }}
        />
      )}

      {isAdmin && (
        <UsersSelect
          required
          name="supervisor_id"
          label="Supervisor"
          margin="dense"
          params={{
            company_id: values?.company_id,
            role: hasRole(rolesMap.admin) ? rolesMap.supervisor : null
          }}
        />
      )}

      <KeyboardDatePicker
        required
        name="date_from"
        label="From date"
        outputFormat="YYYY-MM-DD"
        margin="dense"
      />

      <KeyboardDatePicker
        required
        disableFuture
        name="date_to"
        label="To date"
        outputFormat="YYYY-MM-DD"
        margin="dense"
      />
    </>
  );
};
