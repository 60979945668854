import { useState, useContext, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Box, makeStyles, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { addSocketJobID, jobsTypes } from '../../../../../store/socketJobs';
import { api } from '../../../../../api';
import * as filesApi from '../../../../../api/files';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { IconButton } from '../../../../../components/IconButton';
import { AppBar } from '../../../../../components/AppBar';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { FilesFilterContext } from '../../../files-common';
import { styles } from '../../MyFilesPage/ActionsBar/styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({
  disableActions = false,
  filterOpenButton
}) => {
  const dispatch = useDispatch();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { filter, selectedFilesIDs } = useContext(FilesFilterContext);
  const [ filesCount, setFilesCount ] = useState();
  const classes = useStyles();
  const cancelFetch = useRef(() => {});
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const fetchFilesCount = () => {
    cancelFetch.current();

    filesApi.fetchSharedFiles({
      params: filter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(({ pagination }) => {
      setFilesCount(pagination.total);
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const handleDownloadSelectedFiles = () => {
    filesApi.createArchive({ files: selectedFilesIDs }).then(({ job_id }) => {
      dispatch(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  useEffect(() => {
    selectedFilesIDs.length && fetchFilesCount();

    return () => {
      cancelFetch.current();
    };
  }, [ selectedFilesIDs, filter ]);

  useEffect(() => {
    if (viewVariant === viewVariantsMap.groups) {
      setViewVariant(viewVariantsMap.list);
    }
  }, [ viewVariant ]);

  return (
    <AppBar
      ToolbarProps={{
        className: cn(
          classes.root,
          { [classes.rootNoWrap]: !disableActions }
        )
      }}
    >
      <div className={classes.rightSide}>
        {!!selectedFilesIDs.length &&
          <div className={classes.multiActions}>
            <SelectedItemsCount total={filesCount} length={selectedFilesIDs.length} />

            <VerticalDivider verticalGutters={1} horizontalGutters={2} />

            <IconButton
              edge="start"
              title="Download selected files"
              onClick={handleDownloadSelectedFiles}
            >
              <SvgIcon>
                <DownloadIcon />
              </SvgIcon>
            </IconButton>

            <VerticalDivider verticalGutters={1} horizontalGutters={2} />
          </div>
        }

        {!isTablet && (
          <>
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              title="List view"
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>

            <IconButton
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              title="Grid view"
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </>
        )}

        {isTablet && (
          <Box py={0.25} display="flex" justifyContent="flex-end" alignItems="center" mr={-1}>
            {filterOpenButton}
          </Box>
        )}
      </div>
    </AppBar>
  );
};
