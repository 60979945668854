import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FILES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FILES_SUCCESS]: (
    { filter, files, ...state },
    { data: newFiles, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      files: (page > 1 ?
        files.filter(({ id }) => !newFiles.find((loadedFile) => id === loadedFile.id)).concat(newFiles)
        :
        newFiles
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_FILES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.DELETE_FILES]: ({ pagination, filter, files, ...state }, filesIDs) => {
    const deletedFiles = files.filter((file) => filesIDs.find((id) => id === file.id));
    const total = pagination.total - deletedFiles.length;
    const page = Math.ceil((files.length - deletedFiles.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      files: files.filter((file) => !deletedFiles.find(({ id }) => id === file.id))
    };
  },

  [types.DELETE_ALL_FILES]: ({ pagination, filter, files, ...state }) => {
    return {
      ...state,

      pagination: { ...pagination, total: 0, last_page: 1 },
      filter: { ...filter, page: 1 },
      files: []
    };
  }
});
