export const initialValues = {
  first_name: null,
  last_name: null,
  phone: null,
  email: null,
  mailing_address: null,
  description: null,
  description_of_damage: null,
  incident_type: 'car_accident',
  incident_date: null,
  address: null,
  police_report_filed: '0',
  witnesses: [],
  has_car_insurance: '0',
  has_medical_insurance: '0',
  preferred_contact: {
    date: null,
    time_from: null,
    time_to: null
  }
};
