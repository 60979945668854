import T from 'prop-types';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { styles } from './styles';

export const height = 60;

const useStyles = makeStyles(styles);

const propTypes = {
  viewWidth: T.number,
  fileWidth: T.number,

  pagination: T.shape({
    page: T.number.isRequired,
    total: T.number.isRequired,
    onPrevPage: T.func.isRequired,
    onNextPage: T.func.isRequired
  })
};

export const ViewControls = ({
  pagination,
  viewWidth = 0,
  fileWidth = 0,
  scale,

  ...props
}) => {
  const classes = useStyles();
  const { page, total, onPrevPage, onNextPage } = pagination || {};

  return (
    <div className={classes.root}>
      {pagination &&
        <div className={classes.pagination}>
          <IconButton
            className={classes.button}
            title="Previous page"
            onClick={onPrevPage}
            disabled={page === 1}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          <Typography className={classes.pagination__info}>
            Page
            <span className={classes.pagination__infoPage}>{page}</span>
            /
            <span className={classes.pagination__infoPage}>{total}</span>
          </Typography>

          <IconButton
            className={classes.button}
            title="Next page"
            onClick={onNextPage}
            disabled={page === total}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </div>
      }

      <div className={classes.zoom}>
        <IconButton
          className={classes.button}
          title="Zoom out"
          onClick={props.onZoomOut}
          disabled={scale === 1}
        >
          <RemoveIcon />
        </IconButton>

        {viewWidth !== fileWidth ? (
          <IconButton
            className={classes.button}
            title="Fit to width"
            onClick={props.onZoomFitScreen}
          >
            <ZoomInIcon/>
          </IconButton>
        ) : (
          <IconButton
            className={classes.button}
            title="Rest zoom"
            disabled={pagination ? viewWidth !== fileWidth :  scale === 1}
            onClick={props.onZoomReset}
          >
            <ZoomOutIcon/>
          </IconButton>
        )}

        <IconButton
          className={classes.button}
          title="Zoom in"
          onClick={props.onZoomIn}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

ViewControls.propTypes = propTypes;
