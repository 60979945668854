import { FieldArray, useFormikContext } from 'formik';
import { makeStyles, Grid, Typography, Box, Button } from '@material-ui/core';
import { Radio, FormControlLabel } from '@material-ui/core';
import LocationSearchingOutlinedIcon from '@material-ui/icons/LocationSearchingOutlined';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { Fab } from '../../components/Fab';
import {
  PhoneField,
  KeyboardDatePicker,
  TimePicker,
  TextField,
  RadioGroup,
  AddressAutocomplete,
  KeyboardDateTimePicker
} from '../../components/FormField';
import { IconButton } from '../../components/IconButton';
import { Loader } from '../../components/Loader';
import { MapModal } from '../../components/MapModal';
import { useModal } from '../../components/ModalsProvider';
import { Tooltip } from '../../components/Tooltip';
import { generateAddress } from '../../utils/generateAddress';

// eslint-disable-next-line max-len
const descriptionOfDamage = 'In this section, provide as much detail as you can. Be specific on the nature of the injury (i.e. sprain, fracture, etc.). Be specific on the body part and side of body injured.';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  }
}));

export const Form = () => {
  const classes = useStyles();
  const { isSubmitting, values, setFieldValue } = useFormikContext();
  const address = generateAddress(values?.address);
  const { openModal } = useModal();

  const handleAddressChange = (option) => {
    if (option) {
      setFieldValue(
        'address.street',
        (option.street_number ? option.street_number + ' ' : '') + option.street
      );
      setFieldValue('address.country', option.country);
      setFieldValue('address.state', option.state);
      setFieldValue('address.city', option.city);
      setFieldValue('address.zip', option.zip);
    }
  };

  const openMap = () => {
    openModal(MapModal, {
      payload: {
        address: values?.location?.lat ? '' : generateAddress(values?.address),
        locationName: 'Accident Location',
        lat: values?.location?.lat,
        lng: values?.location?.lang
      },
      onModalResolved: (location) => {
        setFieldValue('location.lat', location.lat);
        setFieldValue('location.lang', location.lng);
      }
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            required
            name="first_name"
            label="First Name"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            required
            name="last_name"
            label="Last Name"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <PhoneField
            required
            name="phone"
            label="Phone Number"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            required
            name="email"
            label="Email Address"
            type="email"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            name="mailing_address"
            label="Mailing Address"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Description of Damage</Typography>

          <TextField
            multiline
            rows={4}
            variant="filled"
            name="description_of_damage"
            placeholder={descriptionOfDamage}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Incident Information
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">
            Type of Incident
          </Typography>

          <RadioGroup
            row
            name="incident_type"
          >
            <FormControlLabel
              value="car_accident"
              control={<Radio color="primary"/>}
              label="Car accident"
            />

            <FormControlLabel
              value="home"
              control={<Radio color="primary"/>}
              label="Home"
            />

            <FormControlLabel
              value="work"
              control={<Radio color="primary"/>}
              label="Work"
            />

            <FormControlLabel
              value="other"
              control={<Radio color="primary"/>}
              label="Other"
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <KeyboardDateTimePicker
            name="incident_date"
            label="Date/Time of Incident"
          />
        </Grid>

        <Grid item container xs={12} sm={6} spacing={1} wrap="nowrap" alignItems="flex-end">
          <Grid item xs>
            <AddressAutocomplete
              name="address.street"
              label="Incident Location"
              placeholder="Enter location..."
              onChange={handleAddressChange}
            />
          </Grid>

          <Grid item>
            <Tooltip
              isExistTooltip={!address}
              title="Enter street address to pick the exact point on the map"
            >
              <div>
                <IconButton
                  disabled={!address}
                  color="primary"
                  onClick={openMap}
                >
                  <LocationSearchingOutlinedIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            zeroMinWidth
            name="address.city"
            label="City"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            zeroMinWidth
            name="address.state"
            label="State"
          />
        </Grid>

        <Grid item sm={4} xs={6}>
          <TextField
            zeroMinWidth
            name="address.zip"
            label="ZIP Code"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">
            Did you file a police report?
          </Typography>

          <RadioGroup
            row
            name="police_report_filed"
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary"/>}
              label="Yes"
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary"/>}
              label="No"
            />
          </RadioGroup>
        </Grid>

        <FieldArray name="witnesses">
          {({ push, remove }) => (
            <>
              <Grid item container alignItems="center" xs={12} spacing={1} className={classes.sectionTitle}>
                <Grid item>
                  <Typography variant="h6">
                    Incident witnesses
                  </Typography>
                </Grid>

                <Grid item>
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={() => push({
                      first_name: null,
                      last_name: null,
                      phone: null
                    })}
                  >
                    Add
                  </Fab>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {values.witnesses.map((witness, index) => (
                  <Box key={index} mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`witnesses.${index}.first_name`}
                            label="First Name"
                            placeholder="Enter first name..."
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`witnesses.${index}.last_name`}
                            label="Last Name"
                            placeholder="Enter last name..."
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <PhoneField
                            name={`witnesses.${index}.phone`}
                            label="Phone Number"
                          />
                        </Grid>
                      </Grid>

                      <Grid item>
                        {values.witnesses.length >= 1 && (
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => remove(index)}
                          >
                            <CloseIcon/>
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </>
          )}
        </FieldArray>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4">Description</Typography>

        <TextField
          multiline
          rows={4}
          variant="filled"
          name="descriptione"
          placeholder="Describe in detail how and why injury happened"
        />
      </Grid>

      {/* Insurance Section */}
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Do you have car insurance?</Typography>

            <RadioGroup
              row
              name="has_car_insurance"
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary"/>}
                label="Yes"
              />

              <FormControlLabel
                value="0"
                control={<Radio color="primary"/>}
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4">Do you have medical insurance?</Typography>

            <RadioGroup
              row
              name="has_medical_insurance"
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary"/>}
                label="Yes"
              />

              <FormControlLabel
                value="0"
                control={<Radio color="primary"/>}
                label="No"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>

      {/* Feedback Time Section */}
      <Box py={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Chose a day and time convenient for feedback
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              name="preferred_contact.date"
              label="Date"
              outputFormat="YYYY-MM-DD"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TimePicker
              fullWidth
              name="preferred_contact.time_from"
              label="Time from"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TimePicker
              fullWidth
              name="preferred_contact.time_to"
              label="Time to"
            />
          </Grid>
        </Grid>
      </Box>

      <div className={classes.buttonContainer}>
        <Loader loading={isSubmitting} render={() => (
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        )}
        />
      </div>
    </>
  );
};
