export const columnsWidths = {
  name: 220,
  status: 160,
  creationDate: 160,
  latestUpdates: 160,
  creator: 160,
  office: 160,
  assignee: 160,
  action: 54
};
