import printJS from 'print-js';
import * as filesApi from '../../api/files';
import { store } from '../../store';
import { closeModal, openModal } from '../../store/dashboard/files/printFile';
import { enqueueSnackbar } from '../../store/snackbars';
import { downloadFile } from './downloadFile';
import { getFileNameExtension } from './getFileNameExtension';

const printSources = [
  {
    type: 'image',
    getPath: ({ url }) => url,
    condition: (file) => {
      return file?.aggregate_type === 'image' && file?.extension !== 'tiff';
    }
  },
  {
    type: 'document',
    getPath: ({ url }) => url,
    condition: (file) => {
      return file?.aggregate_type === 'document';
    }
  },
  {
    type: 'spreadsheet',
    getPath: ({ url }) => url,
    condition: (file) => {
      return file?.aggregate_type === 'spreadsheet';
    }
  },
  {
    type: 'pdf',
    getPath: (file) => file.previews?.pdf || file.url,
    condition: (file) => {
      return !!file && (
        file?.previews?.pdf
        || file?.extension === 'tiff'
        || file?.aggregate_type === 'pdf'
        || !!file?.html_document_id
        || getFileNameExtension(file.original_filename) === 'pdf'
      );
    }
  }
];

export const isPrintable = (file) => {
  return !!file && (
    file?.aggregate_type === 'image'
    || file?.aggregate_type === 'document'
    || file?.aggregate_type === 'spreadsheet'
    || file?.aggregate_type === 'pdf'
    || !!file?.previews?.['pdf']
    || !!file?.html_document_id
  );
};

export const isConvertable = (file) => {
  return (
    file?.aggregate_type === 'image' || file?.aggregate_type === 'document'
  );
};

export const convertFile = (file) => {
  const name = file?.original_filename.substr(0,  file?.original_filename.lastIndexOf('.')) + '.pdf';

  filesApi.convertFile(file?.id, {
    responseType: 'arraybuffer'
  }).then(({ data }) => {
    downloadFile({
      src: new Blob([ data ], { type: 'application/octet-stream' }),
      name
    });
  });
};

const pdfConvertableFiles = [ 'xls', 'xlsx', 'doc', 'docx' ];

export const printFile = (file) => {
  if (!isPrintable(file)) {
    return;
  }

  if (!file.previews?.pdf && pdfConvertableFiles.includes(file.extension)) {
    store.dispatch(openModal());

    return filesApi.convertFile(file.id, {
      responseType: 'arraybuffer'
    }).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({
        printable: url,
        type: 'pdf'
      });
    }).catch((error) => {
      if (error) {
        store.dispatch(enqueueSnackbar('Something went wrong. Try again later...', {
          variant: 'error'
        }));
      }
    }).finally(() => {
      store.dispatch(closeModal());
    });
  }

  const printOptions = printSources.find(({ condition }) => condition(file));

  return printJS({
    printable: printOptions.getPath(file),
    type: printOptions.type
  });
};
