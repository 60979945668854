import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useModal as useModalHook } from 'react-modal-hook';
import { useSnackbar } from 'notistack';
import { flow } from 'lodash';
import { Box, Grid, Link, List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import * as casesApi from '../../../../../../../api/cases';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { Fab } from '../../../../../../../components/Fab';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../../components/Popper';
import { Tooltip } from '../../../../../../../components/Tooltip';
import {
  FilePreviewIcon,
  FilePreviewModal,
  FileUploadModal,
  SelectAvailableFilesModal
} from '../../../../../files-common';
import { filterFieldsMap } from '../../../../../files-common/SelectAvailableFilesModal/FiltersBar';

export const Attachment = ({ name, medias, caseID, onUpdate }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ mediaFile, setMediaFile ] = useState(null);
  const lastUploadedFile = useSelector(({ uploads: { lastUploadedFile } }) => lastUploadedFile);
  const file = medias?.[name]?.media;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ openFilePreview, closeFilePreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilePreview
      }}
      payload={{
        disableFetch: true,
        file,
        singleView: true
      }}
    />
  ), [ file ]);

  const updateCase = (id) => {
    if (!id) {
      return;
    }

    casesApi.updateCaseChecklist(caseID, {
      medias: {
        ...medias,

        [name]: {
          media_id: id
        }
      }
    }).then((checklist) => {
      enqueueSnackbar('File successfully attached', { variant: 'success' });
      onUpdate(checklist);
    }).catch(() => {
      enqueueSnackbar('File not attached', { variant: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const uploadFiles = (name) => () => {
    setMediaFile(null);

    openModal(FileUploadModal, {
      payload: {
        owner_type: 'case',
        owner_id: caseID
      },
      onModalResolved: () => {
        setMediaFile({ name });
      }
    });
  };

  const addAttachment = () => {
    setMediaFile(null);

    openModal(SelectAvailableFilesModal, {
      payload: {
        isSingle: true,
        disableCoping: true,
        filter: {
          cases: [ caseID ]
        },
        hiddenFilterFields: [ filterFieldsMap.cases ]
      },
      onModalResolved: ([ { id } ]) => {
        setIsLoading(true);
        updateCase(id);
      }
    });
  };

  const removeAttachment = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setIsLoading(true);

        casesApi.updateCaseChecklist(caseID, {
          medias: {
            ...medias,

            [name]: {
              media_id: null
            }
          }
        }).then((checklist) => {
          enqueueSnackbar('File successfully detached', { variant: 'success' });
          onUpdate(checklist);
        }).catch(() => {
          enqueueSnackbar('File not detached', { variant: 'error' });
        }).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  useEffect(() => {
    if (mediaFile && mediaFile?.name === name) {
      updateCase(mediaFile?.fileID);
    }
  }, [ mediaFile ]);

  useEffect(() => {
    if (lastUploadedFile?.id && !mediaFile?.fileID && mediaFile?.name === name) {
      setMediaFile({ ...(mediaFile || {}), fileID: lastUploadedFile?.id });
    }
  }, [ lastUploadedFile?.id ]);

  return (
    <Box mt={1}>
      <Loader
        surface
        sx={{ maxWidth: '100%' }}
        loading={isLoading}
        render={() => file ? (
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <FilePreviewIcon file={file} onClick={openFilePreview} />
            </Grid>

            <Grid item style={{ overflow: 'auto' }}>
              <Link noWrap component="p" onClick={openFilePreview}>
                {file.original_filename}
              </Link>
            </Grid>

            <Grid item>
              <Tooltip title="Detach file">
                <IconButton color="error" onClick={removeAttachment}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <>
            <Popper
              anchorRenderer={({ anchorRef, handleToggle }) => (
                <Fab
                  buttonRef={anchorRef}
                  disabled={isLoading}
                  size="small"
                  variant="extended"
                  startIcon={<AttachmentIcon />}
                  onClick={handleToggle}
                >
                  Attach file
                </Fab>
              )}
            >
              {({ handleClose }) => (
                <List>
                  <MenuItem onClick={flow(uploadFiles(name), handleClose)}>
                    <ListItemIcon>
                      <CloudUploadIcon fontSize="inherit" />
                    </ListItemIcon>

                    <ListItemText primary="Upload new file" />
                  </MenuItem>

                  <MenuItem onClick={flow(addAttachment, handleClose)}>
                    <ListItemIcon>
                      <FileCopyIcon fontSize="inherit" />
                    </ListItemIcon>

                    <ListItemText primary="Select from available" />
                  </MenuItem>
                </List>
              )}
            </Popper>
          </>
        )}
      />
    </Box>
  );
};
