/* eslint-disable max-len */

import { Typography, Link } from '@material-ui/core';
import { ServicePageLayout } from '../shared/ServicePageLayout';
import { useServicePageStyles } from '../shared/styles';

export const PrivacyPolicy = () => {
  const classes = useServicePageStyles();

  return (
    <ServicePageLayout title="Privacy Policy">
      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          1. Introduction
        </Typography>

        <Typography paragraph>
          <strong>ITTECHADMIN</strong> ("Company," "we," "us," or "our") owns and operates CaseArk.com ("Website," "Service"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. By using CaseArk, you consent to our data practices outlined in this policy.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          2. Information We Collect
        </Typography>

        <Typography paragraph>
          We collect the following types of personal and business-related data:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Personal Information:</strong> Name, email, phone number, and business details provided during account registration.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Medical and Health Information</strong> (if applicable): CaseArk complies with <strong>HIPAA</strong> and <strong>California Confidentiality of Medical Information Act (CMIA)</strong> when handling <strong>Protected Health Information (PHI)</strong>.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Usage Data:</strong> IP addresses, browser type, and activity logs collected automatically for security and analytics.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Payment Information:</strong> If you subscribe to paid features, your payment details are processed securely by third-party payment processors.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          3. How We Use Your Information
        </Typography>

        <Typography paragraph>
          We use collected data for:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Providing, operating, and improving CaseArk services.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Ensuring compliance with <strong>HIPAA, CMIA, CCPA, and CalOPPA</strong> privacy regulations.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Responding to inquiries, customer support, and security verification.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Processing payments and managing subscriptions.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Monitoring service performance and preventing fraud.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          4. Data Sharing and Disclosure
        </Typography>

        <Typography paragraph>
          We do <strong>not</strong> sell or rent personal information. However, we may share data under the following circumstances:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Legal Compliance:</strong> If required by law, subpoena, or court order.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Business Transfers:</strong> In case of mergers, acquisitions, or restructuring.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Service Providers:</strong> Third-party vendors assisting in analytics, payments, or security compliance.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Medical Data Protection:</strong> For <strong>HIPAA compliance</strong>, medical information is only shared with <strong>authorized</strong> users or as required by law.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          5. California Consumer Privacy Rights (CCPA & CalOPPA Compliance)
        </Typography>

        <Typography paragraph>
          If you are a California resident, you have the right to:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Request Information</strong> about the categories of data we collect.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Request Deletion</strong> of personal data, subject to legal exceptions.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Opt-Out</strong> of certain data sharing practices.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Do Not Track Requests:</strong> CaseArk honors "Do Not Track" signals in compliance with CalOPPA. To exercise your privacy rights, contact us at <Link href="mailto:info@ittechadmin.com">info@ittechadmin.com</Link>.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          6. Data Security
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            All sensitive data, including <strong>PHI</strong>, is encrypted at rest and in transit.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            CaseArk follows <strong>HIPAA Security Rules</strong> to protect medical records.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Access to confidential data is restricted and logged for security monitoring.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            In case of a <strong>data breach</strong>, affected users will be notified as required by law.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          7. Cookies and Tracking Technologies
        </Typography>

        <Typography paragraph>
          CaseArk uses cookies for:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Authentication and security.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Improving website functionality and user experience.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Analyzing service performance through third-party analytics tools. Users can manage cookie preferences through browser settings.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          8. Retention of Data
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We retain personal and medical data as long as required for legal compliance and service purposes.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users may request account deletion; however, legal and billing records may be retained as required.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          9. Children's Privacy
        </Typography>

        <Typography paragraph>
          CaseArk is not intended for users under the age of 18. We do not knowingly collect personal information from minors.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          10. Changes to This Privacy Policy
        </Typography>

        <Typography paragraph>
          We may update this policy from time to time. Any changes will be communicated via email or a notice on the website.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          11. Contact Information
        </Typography>

        <Typography paragraph>
          For any privacy-related questions or to exercise your rights, contact us at:
        </Typography>

        <Typography className={classes.companyInfo}>
          <strong>ITTECHADMIN</strong><br />
          Email: info@ittechadmin.com<br />
          Phone: (818) 647-1937<br />
          Address: 4812 Topanga Can Blvd, Woodland Hills, CA 91364
        </Typography>
      </section>
    </ServicePageLayout>
  );
};
