import { SvgIcon } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline, mdiFax } from '@mdi/js';
import {
  downloadFile as download,
  isPrintable,
  printFile,
  isConvertable,
  convertFile
} from '../../../../../../helpers/files';
import { isCurrentUserID } from '../../../../../../utils/isCurrentUserID';
import { menuOptionsTypesMap } from '../../../../files-common/menuOptionsTypes';

export const getFileMenuOptions = (context) => (file) => [
  {
    type: menuOptionsTypesMap.menuItem,
    Icon: DownloadIcon,
    label: 'Download',
    onClick: () => {
      download({ src: file?.upload_url || file?.url, name: file.original_filename });
    }
  },
  {
    isHidden: !isConvertable(file),
    type: menuOptionsTypesMap.menuItem,
    Icon: PictureAsPdfIcon,
    label: 'Download as PDF',
    onClick: () => {
      if (file.previews.pdf) {
        const name = file.original_filename.substr(0, file.original_filename.lastIndexOf('.')) + '.pdf';

        download({ src: file.previews.pdf, name });
      } else {
        convertFile(file);
      }
    }
  },
  {
    isHidden: !isPrintable(file),
    type: menuOptionsTypesMap.menuItem,
    Icon: PrintIcon,
    label: 'Print file',
    onClick: () => {
      printFile(file);
    }
  },
  {
    isHidden: context.isBillingFile,
    type: menuOptionsTypesMap.divider
  },
  {
    isHidden: file.aggregate_type !== 'pdf' || context.isBillingFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => <SvgIcon {...props}><Icon path={mdiFax} /></SvgIcon>,
    label: 'Send by fax',
    onClick: () => {
      context.sendByFax(file.id);
    }
  },
  {
    isHidden: context.isBillingFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: FileCopyIcon,
    label: 'Make a copy',
    onClick: () => {
      context.copyFile(file);
    }
  },
  {
    isHidden: !isCurrentUserID(file?.creator?.id) || context.isBillingFile,
    type: menuOptionsTypesMap.divider
  },
  {
    isHidden: !isCurrentUserID(file?.creator?.id),
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => (
      <SvgIcon {...props}>
        <Icon path={mdiTrashCanOutline} />
      </SvgIcon>
    ),
    label: 'Delete file',
    onClick: () => {
      context.deleteFiles([ file.id ]);
    }
  }
];
