import { useContext } from 'react';
import { useModal } from '../../ModalsProvider';
import { PersonalInjuriesContext } from './PersonalInjuriesProvider';
import { ViewPersonalInjuryModal } from './ViewPersonalInjuryModal';

export const usePersonalInjury = (piRequest) => {
  const { openModal } = useModal();
  const {
    updatePersonalInjury,
    resetPersonalInjuries,
    deletePersonalInjury
  } = useContext(PersonalInjuriesContext);
  const filesLength = piRequest?.files?.length;

  const openPersonalInjuryPreviewModal = () => {
    openModal(ViewPersonalInjuryModal, {
      payload: {
        piRequest,
        onEdit: updatePersonalInjury,
        onDelete: deletePersonalInjury
      },
      onModalResolved: resetPersonalInjuries
    });
  };

  return {
    filesLength,
    openPersonalInjuryPreviewModal
  };
};
