import { useContext } from 'react';
import {
  makeStyles,
  SvgIcon,
  List,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import RestoreIcon from '@material-ui/icons/RestoreOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { TrashContext } from '../TrashProvider';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const FileMenu = ({ file, openFilePreview }) => {
  const trashContext = useContext(TrashContext);
  const classes = useStyles();

  const deleteFile = () => {
    trashContext.deleteFiles([ file.id ]);
  };

  const restoreFile = () => {
    trashContext.restoreFiles([ file.id ]);
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={openFilePreview}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Preview" />
      </MenuItem>

      <Divider />

      <MenuItem onClick={restoreFile}>
        <ListItemIcon>
          <RestoreIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Restore" />
      </MenuItem>

      <MenuItem onClick={deleteFile}>
        <ListItemIcon>
          <SvgIcon fontSize="inherit">
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete permanently" />
      </MenuItem>
    </List>
  );
};
