import { useEffect } from 'react';
import moment from 'moment';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { CardHeader, CardItem } from '../../../../../../../../components/Cards';
import { Label } from '../../../../../../../../components/Label';
import {
  usePersonalInjury
} from '../../../../../../../../components/medical/personal-injury-requests/usePersonalInjury';
import { statusColorsMap, statusLabelsMap } from '../../../../../../../../components/support/statusesOptions';
import { getUserFullName } from '../../../../../../../../helpers/users';
import { Menu } from '../../Menu';
import { Body } from './Body';

const useStyles = makeStyles(() => ({
  headerTitle: {
    lineHeight: 1
  }
}));

export const Card = ({ item: piRequest, recalculateHeight }) => {
  const classes = useStyles();
  const { filesLength, openPersonalInjuryPreviewModal } = usePersonalInjury(piRequest);

  useEffect(() => {
    recalculateHeight();
  }, [ piRequest ]);

  return (
    <CardItem py={1} px={2} onClick={openPersonalInjuryPreviewModal}>
      <CardHeader
        disableCheckBox
        childrenWidth={12}
        menuComponent={Menu}
        menuComponentProps={{
          piRequest: piRequest
        }}
      >
        <Typography variant="subtitle1"className={classes.headerTitle}>
          {getUserFullName(piRequest)}
        </Typography>
      </CardHeader>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Label label={statusLabelsMap[piRequest?.status]} color={statusColorsMap[piRequest?.status]} />
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Status
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {moment.utc(piRequest?.created_at).local().format('L LT')}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Latest updates
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {moment.utc(piRequest?.changed_at).local().format('L LT')}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Creation date
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box py={1}>
        <Body piRequest={piRequest} filesLength={filesLength} />
      </Box>
    </CardItem>
  );
};
