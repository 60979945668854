import { useContext, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../saved-filters';
import { IconButton } from '../../../../IconButton';
import { Members } from '../../../../Members';
import { Loader } from '../../../../Loader';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { filterFieldsMap } from '../../filterFieldsMap';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  hiddenColumns,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.title}>
        {params?.title || '-'}
      </ListRowCell>

      {!hiddenColumns.includes(filterFieldsMap.cases) && (
        <ListRowCell flexBasis={columnsWidths.cases}>
          <Box display="flex" flexDirection="column" width="100%">
            {params?.cases?.length ? params?.cases?.map((caseItem, index) => (
              <Typography noWrap key={index} title={caseItem.name}>
                {caseItem.name + ' '}
              </Typography>
            )) : '-'}
          </Box>
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.tasks}>
        <Box display="flex" flexDirection="column" width="100%">
          {params?.tasks?.length ? params?.tasks?.map((task, index) => (
            <Typography noWrap key={index} title={task.name}>
              {task.name + ' '}
            </Typography>
          )) : '-'}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        <Members
          noWrap
          maxVisible={4}
          users={filter?.params.users || []}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.offices}>
        <Box display="flex" flexDirection="column" width="100%">
          {params?.offices?.length ? params?.offices?.map((office, index) => (
            <Typography noWrap key={index} title={office.name}>
              {office.name + ' '}
            </Typography>
          )) : '-'}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

