import { useState } from 'react';
import { makeStyles, Dialog } from '@material-ui/core';
import { Loader } from '../../../../components/Loader';
import { FilePreviewContent } from './FilePreviewContent';
import { FilePreviewContext, FilePreviewContextProvider } from './FilePreviewContext';
import { PreviewWindow } from './PreviewWindow';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FilePreviewModal = ({
  previewMenuOptions,
  listItemMenuOptions = [],
  getFileMenuOptions = () => {},
  DialogProps: {
    BackdropProps,
    PaperProps,
    onCancel,

    ...DialogProps
  },
  payload: {
    file,
    disableFetch = false,
    singleView = false,
    files = [],
    isFetched,
    isFetching,
    filter = {},
    onNeedNextPage
  }
}) => {
  const [ isModalEntered, setIsModalEntered ] = useState(false);
  const handleModalEntered = () => setIsModalEntered(true);
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      onEntered={handleModalEntered}
      onCancel={onCancel}
      onEscapeKeyDown={onCancel}
      classes={{
        paper: classes.paper
      }}
      BackdropProps={{
        ...BackdropProps,

        invisible: true
      }}
      {...DialogProps}
    >
      <FilePreviewContextProvider disableFetch={disableFetch} initialFile={file} files={files}>
        <div className={classes.root}>
          <div className={classes.preview}>
            {!isModalEntered ? (
              <Loader className={classes.loader} />
            ) : (
              <PreviewWindow
                singleView={singleView}
                previewMenuOptions={previewMenuOptions}
                getFileMenuOptions={getFileMenuOptions}
                onModalClose={onCancel}
              />
            )}
          </div>

          {singleView ||
            <FilePreviewContext.Consumer>
              {({ sidebarIsOpen, toggleSidebarIsOpen }) => (
                <FilePreviewContent
                  files={files}
                  isFetched={isFetched}
                  isFetching={isFetching}
                  filter={filter}
                  listItemMenuOptions={listItemMenuOptions}
                  getFileMenuOptions={getFileMenuOptions}
                  onNeedNextPage={onNeedNextPage}
                  isModalEntered={isModalEntered}
                  sidebarIsOpen={sidebarIsOpen}
                  handleToggleSidebarIsOpen={toggleSidebarIsOpen}
                />
              )}
            </FilePreviewContext.Consumer>
          }
        </div>
      </FilePreviewContextProvider>
    </Dialog>
  );
};
