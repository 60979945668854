import { api } from '../api';

export const fetchPersonalInjuries = (config = {}) => {
  return api.get('/medical-documents/personal-injury', config).then(({ data: { data, pagination } }) => {
    return { data, pagination };
  }).catch((thrown) => {
    throw thrown;
  });
};

export const sendPersonalInjury = (data, config) => {
  return api.post('/public/medical-documents/personal-injury', data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchPersonalInjury = (id) => {
  return api.get(`/medical-documents/personal-injury/${id}`).then(({ data }) => data);
};

export const updatePersonalInjury = (id, data) => {
  return api.put(`/medical-documents/personal-injury/${id}`, data).then(({ data }) => data);
};

export const updatePersonalInjuryStatus = (id, data) => {
  return api.put(`/medical-documents/personal-injury/${id}/status`, data).then(({ data }) => data);
};

export const deletePersonalInjury = (id) => {
  return api.delete(`/medical-documents/personal-injury/${id}`).then(({ data }) => data);
};
