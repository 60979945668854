import { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as filesApi from '../../../../api/files';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import * as filesActionsTypes from '../FilesContext/types';

export const FilePreviewContext = createContext();

export const FilePreviewContextProvider = ({
  disableFetch,
  children,
  initialFile,
  files = []
}) => {
  const [ selectedFile, setSelectedFile ] = useState();
  const [ viewWidth, setViewWidth ] = useState();
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(true);
  const filesLastGlobalAction = useSelector(({ globalActions: { filesLastGlobalAction } }) => filesLastGlobalAction);
  const dispatch = useDispatch();
  const fileIndex = files.findIndex(({ id }) => id === selectedFile?.id);
  const hasPreviousFile = fileIndex > 0;
  const hasNextFile = fileIndex + 1 < files.length;

  const toggleToPreviousFile = () => {
    if (hasPreviousFile) {
      handleFileSelect(files[fileIndex - 1]);
    }
  };

  const toggleToNextFile = () => {
    if (hasNextFile) {
      handleFileSelect(files[fileIndex + 1]);
    }
  };

  const handleFileSelect = (file) => {
    if (file?.id && !disableFetch && (!selectedFile || file.id !== selectedFile?.id) && !file?.deleted_at) {
      filesApi.openFile(file).then((data) => {
        dispatch(setFilesLastGlobalAction({ type: filesActionsTypes.UPDATE_FILE, payload: data }));
        dispatch(setFilesLastGlobalAction({ type: filesActionsTypes.REFRESH_FILE, payload: data }));
      });
    }

    setSelectedFile(file);
  };

  const toggleSidebarIsOpen = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  useEffect(() => {
    handleFileSelect(initialFile);
  }, []);

  useEffect(() => {
    if (selectedFile && files.length && !files.find(({ id }) => id === selectedFile?.id)) {
      handleFileSelect({});
    }
  }, [ files ]);

  useEffect(() => {
    if (!selectedFile || !filesLastGlobalAction) {
      return;
    }

    if (filesLastGlobalAction.type === filesActionsTypes.UPDATE_FILE) {
      if (filesLastGlobalAction.payload?.id === selectedFile?.id) {
        handleFileSelect(filesLastGlobalAction.payload);
      }
    }
  }, [ hasNextFile, filesLastGlobalAction ]);

  const providerValue = {
    selectedFile,
    viewWidth,
    sidebarIsOpen,
    hasPreviousFile,
    hasNextFile,
    setViewWidth,
    handleFileSelect,
    toggleSidebarIsOpen,
    toggleToPreviousFile,
    toggleToNextFile
  };

  return (
    <FilePreviewContext.Provider value={providerValue}>
      {children}
    </FilePreviewContext.Provider>
  );
};
