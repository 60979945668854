export const statusesMap = {
  new: 'new',
  approved: 'approved',
  rejected: 'rejected'
};

export const statusLabelsMap = {
  [statusesMap.new]: 'New',
  [statusesMap.approved]: 'Approved',
  [statusesMap.rejected]: 'Rejected'
};

export const statusColorsMap = {
  [statusesMap.new]: '#F44336',
  [statusesMap.approved]: '#4DB6AC',
  [statusesMap.rejected]: '#C62828'
};

export const statusesOptions = [
  {
    value: statusesMap.new,
    label: statusLabelsMap[statusesMap.new]
  },
  {
    value: statusesMap.approved,
    label: statusLabelsMap[statusesMap.approved]
  },
  {
    value: statusesMap.rejected,
    label: statusLabelsMap[statusesMap.rejected]
  }
];
