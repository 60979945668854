import { createContext, useRef, useContext, useEffect, useReducer } from 'react';
import { merge, omit, isEqual, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useMemoEffect, usePrevious } from '../../../../helpers/hooks';
import { useQueryParams } from '../../../../helpers/hooks/useQueryParams';
import { api } from '../../../../api';
import * as filesApi from '../../../../api/files';
import { tagsActionsMap } from '../../../../api/files/tags';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import { jobsTypes, addSocketJobID } from '../../../../store/socketJobs';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { SendFaxModal } from '../../FaxPage/ActionsBar/SendFaxModal';
import { AiAssistanceModal } from '../AiAssistanceModal';
import { ChangeFilesTagsModal } from '../ChangeFilesTagsModal';
import { ShareByEmailModal } from '../ShareByEmailModal';
import { ShareFileModal } from '../ShareFileModal';
import { CopyFileModal } from '../CopyFileModal';
import { MoveFileModal } from '../MoveFileModal';
import { RenameFileModal } from '../RenameFileModal';
import { EditDocumentModal } from '../EditDocumentModal';
import * as tagsActionsTypes from '../TagsContext/types';
import { FilesFilterContext } from '../FilesFilterContext';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as filesActionTypes from './types';
import * as types from './types';

export const FilesContext = createContext();

export const FilesContextProvider = ({
  children,
  disableFetching,
  isSharedFiles = false,
  owner = null,
  initialState: initialStateProp = {},
  onFilesCountChange = () => {}
}) => {
  const { openModal } = useModal();
  const { token } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatch = useDispatch();
  const currentUser = useSelector(({ profile: { user } }) => user);
  const lastUploadedFile = useSelector(({ uploads: { lastUploadedFile } }) => lastUploadedFile);
  const { filesLastGlobalAction, tagsLastGlobalAction } = useSelector(({ globalActions }) => globalActions);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { isFetched, isFetching, filter, pagination } = state;
  const {
    filter: commonFilter,
    selectedFilesIDs,
    addFilesToSelected,
    deleteFilesFromSelected
  } = useContext(FilesFilterContext);
  const files = useRef(state.files);
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchFiles = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FILES_REQUEST });

    if (!disableFetching) {
      filesApi[isSharedFiles ? 'fetchSharedFiles' : 'fetchFiles']({
        params: omit({
          ...filter,
          ...newFilter,

          tags: uniq((filter.tags || []).concat(newFilter.tags || [], newFilter.folders || []))
        }, [ 'folders' ]),
        cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel),
        headers: {
          authorization: token && `Bearer ${token}`
        }
      }).then((data) => {
        dispatch({ type: types.FETCH_FILES_SUCCESS, payload: data });
      });
    }
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFiles({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetFiles = (newFilter) => {
    dispatch({ type: types.RESET_FILES, payload: newFilter });
    deleteFilesFromSelected(files.current.map(({ id }) => id));
    fetchFiles({ page: 1, ...newFilter });
  };

  const deleteFiles = (filesIDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.deleteFiles(filesIDs).then(() => {
          reduxDispatch(setFilesLastGlobalAction({ type: types.DELETE_FILES, payload: filesIDs }));

          enqueueSnackbar(`${filesIDs.length} file successfully deleted`, { variant: 'success' });
        });
      }
    });
  };

  const deleteFilesSharedWithMe = (filesIDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.preventShareFiles({ params: { filesIDs, users: [ currentUser.id ] } }).then(() => {
          reduxDispatch(setFilesLastGlobalAction({ type: types.DELETE_FILES, payload: filesIDs }));

          enqueueSnackbar(`${files.length} file successfully deleted`, {
            variant: 'success'
          });
        });
      }
    });
  };

  const renameFile = (file) => {
    openModal(RenameFileModal, { payload: { file } });
  };

  const shareFiles = ({ filesIDs, users }) => {
    openModal(ShareFileModal, { payload: { filesIDs, users } });
  };

  const changeFilesTags = (payload) => {
    dispatch({ type: filesActionTypes.CHANGE_FILES_TAGS, payload });
  };

  const shareByEmail = (filesIDs) => {
    const usersOptions = owner?.case_users?.map((caseUser) => caseUser?.user)?.filter((user) => !!user);

    openModal(ShareByEmailModal, {
      payload: {
        usersOptions
      },
      onModalResolved: (data) => {
        filesApi.shareByEmail({
          share_tag_id: data?.data?.additional_data?.tag?.id,
          files: filesIDs
        }).then(() => {
          changeFilesTags({
            filesIDs,
            tags: [ data?.data?.additional_data?.tag ],
            actionType: tagsActionsMap.add
          });

          enqueueSnackbar(`File${filesIDs.length > 1 ? 's' : ''} successfully shared`, { variant: 'success' });
        });
      }
    });
  };

  const preventShareByEmail = ({ file, tag }) => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Revoke access for this file?'
      },

      onModalResolved: () => {
        filesApi.preventShareByEmail({
          params: {
            share_tag_id: tag.id,
            files: [ file.id ]
          }
        }).then(() => {
          changeFilesTags({ filesIDs: [ file.id ], tags: [ tag ], actionType: tagsActionsMap.delete });

          enqueueSnackbar('Access for this email has been revoked', { variant: 'success' });
        });
      }
    });
  };

  const sendByFax = (fileID) => {
    openModal(SendFaxModal, {
      payload: {
        initialValues: {
          file_id: fileID
        }
      }
    });
  };

  const copyFile = (file) => {
    openModal(CopyFileModal, { payload: { file } });
  };

  const moveFile = (file) => {
    openModal(MoveFileModal, { payload: { file } });
  };

  const editDocument = (documentID) => {
    openModal(EditDocumentModal, { payload: { documentID } });
  };

  const openDocumentForEdit = (fileId) => {
    return filesApi.getEditFileUrl(fileId).then((response) => {
      const url = response?.url;

      if (url) {
        window.open(url);
      }
    });
  };

  const openChangeFilesTagsModal = (filesIDs) => {
    openModal(ChangeFilesTagsModal, { payload: { filesIDs } });
  };

  const aiAssistance = (fileId) => {
    openModal(AiAssistanceModal, { payload: { fileId } });
  };

  const downloadFiles = (filesIDs) => {
    filesApi.createArchive({ files: filesIDs }).then(({ job_id }) => {
      reduxDispatch(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  const allFilesIsSelected = () => {
    return selectedFilesIDs.filter((id) => state.files.find((file) => file.id === id)).length === state.files.length;
  };

  const toggleAllFilesSelection = () => {
    const filesIDs = state.files.map(({ id }) => id);

    allFilesIsSelected() ? deleteFilesFromSelected(filesIDs) : addFilesToSelected(filesIDs);
  };

  const addFiles = (files) => {
    dispatch({ type: types.ADD_FILES, payload: files });
  };

  const providerValue = {
    isSharedFiles,
    isFetched,
    isFetching,
    files: state.files,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    applyFilter,
    resetFiles,
    fetchFiles,
    loadNextPage,
    deleteFiles,
    deleteFilesSharedWithMe,
    downloadFiles,
    renameFile,
    shareFiles,
    shareByEmail,
    preventShareByEmail,
    copyFile,
    moveFile,
    aiAssistance,
    editDocument,
    addFiles,
    openChangeFilesTagsModal,
    changeFilesTags,
    allFilesIsSelected,
    toggleAllFilesSelection,
    sendByFax,
    openDocumentForEdit
  };

  useEffect(() => {
    files.current = state.files;
  }, [ state.files ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
      deleteFilesFromSelected(files.current.map(({ id }) => id));
    };
  }, []);

  useEffect(() => {
    lastUploadedFile && addFiles([ lastUploadedFile ]);
  }, [ lastUploadedFile ]);

  useEffect(() => {
    if (tagsLastGlobalAction && tagsLastGlobalAction.type === tagsActionsTypes.UPDATE_TAG) {
      dispatch({ type: types.UPDATE_TAG, payload: tagsLastGlobalAction });
    }
  }, [ tagsLastGlobalAction ]);

  useEffect(() => {
    filesLastGlobalAction && dispatch(filesLastGlobalAction);
  }, [ filesLastGlobalAction ]);

  useMemoEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(commonFilter);
    }
  }, [ { ...omit(filter, [ 'page' ]), ...commonFilter } ]);

  useMemoEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!disableFetching && !isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFiles(newFilter);
    }
  }, [ prevFilter, omit(filter, [ 'page' ]) ]);

  useEffect(() => {
    onFilesCountChange(pagination.total);
  }, [ pagination.total ]);

  return (
    <FilesContext.Provider value={providerValue}>
      {children}
    </FilesContext.Provider>
  );
};
