import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as piRequestsApi from '../../../../api/personal-injury-requests';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../Modal';
import { Loader } from '../../../Loader';
import { PersonalInjuryForm, validationSchema } from '../PersonalInjuryForm';

export const EditPersonalInjuryModal = ({
  DialogProps,
  payload: {
    initialValues
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const updateRequest = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return piRequestsApi.updatePersonalInjuryStatus(values.id, values).then((ticket) => {
      enqueueSnackbar('PI request successfully updated', { variant: 'success' });
      handleModalResolve(ticket);
    }).catch(({ data: { errors } = {} }) => {
      setErrors(errors);

      enqueueSnackbar('PI request not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateRequest}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Edit PI request
            </ModalHeader>

            <ModalBody>
              <PersonalInjuryForm />
            </ModalBody>

            <ModalFooter>
              <Button disabled={isSubmitting} onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
