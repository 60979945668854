import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { useContext } from 'react';
import { flow } from 'lodash';
import { IconButton, List, ListItemIcon, ListItemText, MenuItem, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { stopPropagation } from '../../../../../helpers/dom';
import { SelectAvailableFilesModal } from '../../../files-common';
import { PageHeader } from '../../../../../components/Page';
import { useModal } from '../../../../../components/ModalsProvider';
import { Popper } from '../../../../../components/Popper';
import { Fab } from '../../../../../components/Fab';
import { viewVariantsMap, LayoutContext } from '../../../../../components/LayoutContext';
import { PageHeaderActions } from '../../../../../components/Page';
import { Tooltip } from '../../../../../components/Tooltip';
import { TranscribesContext } from '../TranscribesProvider';
import { FilesUploadModal } from './FilesUploadModal';
import { SendTranscribeModal } from './SendTranscribeModal';

export const ActionsBar = () => {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { openModal } = useModal();
  const { resetTranscribes } = useContext(TranscribesContext);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        isSingle: true,
        disableCoping: true,
        filter: {
          aggregate_type: 'audio'
        }
      },

      onModalResolved: ([ { id } ]) => {
        openModal(SendTranscribeModal, {
          payload: {
            initialValues: {
              file_id: id
            }
          },

          onModalResolved: resetTranscribes
        });
      }
    });
  };

  const uploadFile = () => {
    openModal(FilesUploadModal, {
      onModalResolved: (files) => {
        openModal(SendTranscribeModal, {
          payload: {
            initialValues: {
              file: files[0]
            }
          },

          onModalResolved: resetTranscribes
        });
      }
    });
  };

  return (
    <PageHeader>
      <Popper
        placement="bottom-start"
        anchorRenderer={({ anchorRef, handleToggle }) => (
          <Fab
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
            buttonRef={anchorRef}
            onClick={stopPropagation(handleToggle)}
          >
            Send new
          </Fab>
        )}
      >
        {({ handleClose }) => (
          <List>
            <MenuItem onClick={flow(uploadFile, handleClose)}>
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>

              <ListItemText primary="Upload new file" />
            </MenuItem>

            <MenuItem onClick={flow(openAvailableFilesModal, handleClose)}>
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>

              <ListItemText primary="Select from available" />
            </MenuItem>
          </List>
        )}
      </Popper>

      {!isTablet &&
        <PageHeaderActions endPosition disableSpacing>
          <Tooltip arrow title="List view">
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon/>
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Grid view">
            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon/>
            </IconButton>
          </Tooltip>
        </PageHeaderActions>
      }
    </PageHeader>
  );
};
