import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersBar as CommonFiltersBar } from '../../../../components/FiltersBar';
import { OfficesLocationSelect, TextField } from '../../../../components/FormField';
import { IconComponent } from '../../../../components/saved-filters';
import { SupportTicketCategoriesSelect } from '../../../../components/support/SupportTicketCategoriesSelect';
import { SupportTicketsContext } from '../../../../components/support/SupportTicketsProvider';
import { SupportTicketStatusesSelect } from '../../../../components/support/SupportTicketStatusesSelect';
import { UsersSelect } from '../../../../components/users';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { saveFilter } from '../../../../store/lastFilters';
import { hasRole } from '../../../../utils/hasRole';
import { transformRelationsToFilter } from './utils';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { CardContent } from './CardContent';
import { List } from './List';

const initialValues = {
  search: null,
  status: null,
  issuer_id: null,
  assignee_id: null,
  creator_id: null,
  category: null
};

export const FiltersBar = ({ filterKey }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(SupportTicketsContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const currentUser = useSelector(({ profile }) => profile.user);

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(filter);
    applyFilter(transformRelationsToFilter(filter));
  };

  const handleFieldChange = (name) => (event) => {
    applyFilter({ [name]: event.target.value });
    setRelationsForFilter((state) => ({ ...state, [name]: event.target.value }));
  };

  const handleStatusChange = (status) => {
    applyFilter({ status: status?.value });
    setRelationsForFilter((state) => ({ ...state, status }));
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleAssigneeChange = (user) => {
    applyFilter({ assignee_id: user?.id });
    setRelationsForFilter((state) => ({ ...state, assignee_id: user }));
  };

  const handleIssuerChange = (user) => {
    applyFilter({ issuer_id: user?.id });
    setRelationsForFilter((state) => ({ ...state, issuer_id: user }));
  };

  const handleCreatorChange = (user) => {
    applyFilter({ creator_id: user?.id });
    setRelationsForFilter((state) => ({ ...state, creator_id: user }));
  };

  const handleCategoryChange = (category) => {
    applyFilter({ category: category?.value });
    setRelationsForFilter((state) => ({ ...state, category }));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        ...lastFilters,
        ...relationsForFilter
      });
    }
  }, []);

  return (
    <CommonFiltersBar
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={(
        <IconComponent
          modalWidth={1180}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.search,
          label: filterLabels[fieldsKeysMap.search],
          field: (
            <TextField
              name={fieldsKeysMap.search}
              label={filterLabels[fieldsKeysMap.search]}
              placeholder="Search..."
              onChange={handleFieldChange(fieldsKeysMap.search)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.status,
          label: filterLabels[fieldsKeysMap.status],
          field: (
            <SupportTicketStatusesSelect
              name={fieldsKeysMap.status}
              label={filterLabels[fieldsKeysMap.status]}
              onChange={handleStatusChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.office_id,
          label: filterLabels[fieldsKeysMap.office_id],
          field: (
            <OfficesLocationSelect
              name={fieldsKeysMap.office_id}
              label={filterLabels[fieldsKeysMap.office_id]}
              params={{ company_id: hasRole(rolesMap.admin) ? null : currentUser?.work?.company_id }}
              onChange={handleOfficeChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.issuer_id,
          label: filterLabels[fieldsKeysMap.issuer_id],
          field: (
            <UsersSelect
              name={fieldsKeysMap.issuer_id}
              label={filterLabels[fieldsKeysMap.issuer_id]}
              onChange={handleIssuerChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.assignee_id,
          label: filterLabels[fieldsKeysMap.assignee_id],
          field: (
            <UsersSelect
              name={fieldsKeysMap.assignee_id}
              label={filterLabels[fieldsKeysMap.assignee_id]}
              params={{ roles: [ rolesMap.admin, rolesMap.supervisor ] }}
              onChange={handleAssigneeChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.creator_id,
          label: filterLabels[fieldsKeysMap.creator_id],
          field: (
            <UsersSelect
              name={fieldsKeysMap.creator_id}
              label={filterLabels[fieldsKeysMap.creator_id]}
              params={{ is_confirmed: 1 }}
              onChange={handleCreatorChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.category,
          label: filterLabels[fieldsKeysMap.category],
          field: (
            <SupportTicketCategoriesSelect
              name={fieldsKeysMap.category}
              label={filterLabels[fieldsKeysMap.category]}
              onChange={handleCategoryChange}
            />
          )
        }
      ]}
    />
  );
};
