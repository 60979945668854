import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { omit, uniqBy } from 'lodash';
import moment from 'moment';
import { FormControl, FormControlLabel, Grid } from '@material-ui/core';
import { apiModelsMap } from '../../../dataMaps/apiModels';
import { CasesSelect } from '../../cases/CasesSelect';
import { ConfirmationModal } from '../../ConfirmationModal';
import {
  Editor,
  TextField,
  KeyboardDateTimePicker,
  TimeZoneSelect,
  TasksSelect,
  OfficesLocationSelect,
  ScheduleEventTypesSelect,
  transformScheduleEventTypeToOption,
  Switch,
  Autocomplete
} from '../../FormField';
import { useModal } from '../../ModalsProvider';
import { RecurrenceSelect } from '../../RecurrenceSelect';
import { UsersSelect } from '../../users';
import { eventsParentsTypesOptions } from '../eventsParentsTypesOptions';
import { ScheduleEventTemplatesSelect } from '../templates';
import { SetScheduleEventUsersModal } from './SetScheduleEventUsersModal';

export const ScheduleEventForm = ({ hideApplyFields = false }) => {
  const { openModal } = useModal();
  const { values, setFieldValue, setValues } = useFormikContext();
  const currentUser = useSelector(({ profile }) => profile.user);
  const editorRef = useRef();

  const handleTemplateChange = (template) => {
    openModal(ConfirmationModal, {
      DialogProps: {
        disableRestoreFocus: true
      },
      payload: {
        content: 'All form data will be replaced from the template'
      },

      onModalResolved: () => {
        editorRef.current.setData(template.description);

        setValues({
          ...values,
          ...omit(template, [ 'id', 'started_at', 'finished_at', 'users' ]),

          type_id: transformScheduleEventTypeToOption(template.type || null),
          office_id: template.office || null
        });

        if (values.users?.length) {
          openModal(SetScheduleEventUsersModal, {
            payload: {
              currentUsers: values.users,
              newUsers: template.users
            },
            onModalResolved: (users) => {
              setFieldValue('users', users);
            }
          });
        } else {
          setFieldValue('users', template.users);
        }
      }
    });
  };

  const handleStartDateChange = (date, isDurationCorrespondsToType = values.is_duration_corresponds_to_type) => {
    if (!date) {
      return;
    }

    if (isDurationCorrespondsToType && values.type?.duration) {
      setFieldValue('finished_at', moment.unix(date).add(values.type?.duration, 'seconds'));

      return;
    }

    if (values.finished_at < date) {
      setFieldValue('finished_at', moment.unix(date).add(5, 'minutes'));
    }
  };

  const bindEventDurationToType = ({ target: { checked } }) => {
    if (checked) {
      handleStartDateChange(values.started_at, checked);
    }
  };

  const handleParentTypeChange = () => {
    setFieldValue('parent_id', null);
  };

  const handleEventTypeChange = (option) => {
    setFieldValue('type', option?.data);

    if (values.started_at && option?.data?.duration) {
      setFieldValue('finished_at', moment.unix(values.started_at).add(option.data?.duration, 'seconds'));
    }
  };

  const handleCaseChange = (caseItem) => {
    const uniqueCaseUsers = uniqBy(
      caseItem?.case_users?.reduce((users, { user }) => user ? users.concat(user) : users, []),
      'id'
    );

    if (values.users?.length) {
      openModal(SetScheduleEventUsersModal, {
        payload: {
          currentUsers: values.users,
          newUsers: uniqueCaseUsers
        },
        onModalResolved: (users) => {
          setFieldValue('users', users);
        }
      });
    } else {
      setFieldValue('users', uniqueCaseUsers);
    }
  };

  const handleTaskChange = (task) => {
    if (values.users?.length) {
      openModal(SetScheduleEventUsersModal, {
        payload: {
          currentUsers: values.users,
          newUsers: task?.users
        },
        onModalResolved: (users) => {
          setFieldValue('users', users);
        }
      });
    } else {
      setFieldValue('users', task?.users);
    }
  };

  return (
    <>
      {!values?.id &&
        <ScheduleEventTemplatesSelect
          withoutFormik
          disableClearable
          margin="dense"
          onChange={handleTemplateChange}
        />
      }

      <TextField
        required
        name="title"
        label="Title"
        placeholder="Enter event title..."
        margin="dense"
      />

      <UsersSelect
        multiple
        showCompany={!!values.show_all_users}
        formattedValue={false}
        name="users"
        label="Select users"
        margin="dense"
        params={{
          is_confirmed: 1,
          company_id: values.show_all_users ? null : currentUser?.work?.company_id
        }}
      />

      <FormControlLabel
        label="Show users from all companies"
        control={
          <Switch name="show_all_users" color="primary" />
        }
      />

      <Editor
        ref={editorRef}
        name="description"
        label="Description"
        placeholder="Enter description..."
        margin="dense"
      />

      <ScheduleEventTypesSelect
        isClearable
        name="type_id"
        label="Event type"
        TextFieldProps={{ margin: 'dense' }}
        onChange={handleEventTypeChange}
      />

      <FormControl margin="dense">
        <FormControlLabel
          label="Bind event duration to event type"
          control={
            <Switch
              color="primary"
              name="is_duration_corresponds_to_type"
              onChange={bindEventDurationToType}
            />
          }
        />
      </FormControl>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <KeyboardDateTimePicker
            required
            name="started_at"
            label="Started"
            margin="dense"
            onChange={handleStartDateChange}
          />
        </Grid>

        <Grid item sm={6}>
          <KeyboardDateTimePicker
            required
            disabled={!!values.is_duration_corresponds_to_type && !!values.type?.duration}
            name="finished_at"
            label="Ended"
            margin="dense"
            minDate={moment.unix(values.started_at).isValid() ? values.started_at : null}
          />
        </Grid>

        <Grid item sm={6}>
          <RecurrenceSelect
            name="recurring_rule"
            label="Recurrence"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6}>
          <TimeZoneSelect name="timezone" margin="dense" />
        </Grid>
      </Grid>

      {!hideApplyFields &&
        <>
          <Autocomplete
            disableSearch
            name="parent_type"
            label="Apply to"
            margin="dense"
            options={eventsParentsTypesOptions}
            onChange={handleParentTypeChange}
          />

          {values.parent_type === apiModelsMap.case &&
            <CasesSelect
              required
              name="parent_id"
              label="Select case"
              placeholder="Search case by name..."
              margin="dense"
              onChange={handleCaseChange}
            />
          }

          {values.parent_type === apiModelsMap.task &&
            <TasksSelect
              required
              name="parent_id"
              label="Select task"
              margin="dense"
              onChange={handleTaskChange}
            />
          }
        </>
      }

      <OfficesLocationSelect
        name="office_id"
        label="Office"
        margin="dense"
      />

      <FormControl margin="dense">
        <FormControlLabel
          label="Send them e-mail with notification"
          control={<Switch color="primary" name="email_notification"/>}
        />
      </FormControl>
    </>
  );
};
