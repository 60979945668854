import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_PERSONAL_INJURIES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_PERSONAL_INJURIES_SUCCESS]: (
    { filter, personalInjuries, ...state },
    { data: newPersonalInjury, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      personalInjuries: (page > 1
        ? personalInjuries.filter(({ id }) => {
          return !newPersonalInjury.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newPersonalInjury)
        : newPersonalInjury
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_PERSONAL_INJURIES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_PERSONAL_INJURY]: (state, personalInjury) => {
    return {
      ...state,

      personalInjuries: state.personalInjuries.map((item) => {
        return item.id === personalInjury.id ? { ...item, ...personalInjury } : item;
      })
    };
  },

  [types.ADD_PERSONAL_INJURY]: (state, personalInjury) => {
    const { pagination, filter, personalInjuries } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((personalInjuries.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      personalInjuries: [ personalInjury, ...personalInjuries ]
    };
  },

  [types.DELETE_PERSONAL_INJURY]: (state, personalInjuryID) => {
    const { pagination, filter, personalInjuries } = state;
    const filteredPersonalInjuries = personalInjuries.filter(({ id }) => personalInjuryID !== id);

    if (filteredPersonalInjuries.length === personalInjuries.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((personalInjuries.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      personalInjuries: filteredPersonalInjuries
    };
  }
});
