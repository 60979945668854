import { Dialog, Button } from '@material-ui/core';
import {
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  InsertThematicBreak,
  ListsToggle,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  StrikeThroughSupSubToggles
} from '@mdxeditor/editor';
import markdownToTxt from 'markdown-to-txt';
import he from 'he';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { updateTranscribe } from '../../../../../api/files/transcribes';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';

export const EditTranscribeModal = ({
  DialogProps,
  payload: {
    transcribe,
    markdown
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const plugins = [
    toolbarPlugin({
      toolbarContents: () => (
        <DiffSourceToggleWrapper>
          <UndoRedo />
          <BoldItalicUnderlineToggles />,
          <StrikeThroughSupSubToggles />,
          <BlockTypeSelect />,
          <InsertThematicBreak />,
          <ListsToggle />
        </DiffSourceToggleWrapper>
      )
    }),
    headingsPlugin(),
    listsPlugin(),
    quotePlugin(),
    thematicBreakPlugin(),
    diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: markdown }),
    markdownShortcutPlugin()
  ];

  const handleSubmit = () => {
    setIsSubmitting(true);

    const markdown = ref.current?.getMarkdown();
    const text = markdownToTxt(markdown);

    updateTranscribe({
      ...transcribe,

      formatted_text: markdown,
      text: he.decode(text)
    }).then((transcribe) => {
      enqueueSnackbar('Transcribe successfully updated', { variant: 'success' });
      handleModalResolve(transcribe);
    }).catch(() => {
      enqueueSnackbar('Transcribe was not updated', { variant: 'error' });
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <ModalHeader onClose={handleModalReject}>
        Edit transcribe
      </ModalHeader>

      <ModalBody>
        <MDXEditor ref={ref} markdown={markdown} plugins={plugins} />
      </ModalBody>

      <ModalFooter>
        <Button disabled={isSubmitting} onClick={handleModalReject}>
          Cancel
        </Button>

        <Loader surface loading={isSubmitting} render={
          () => (
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}
        />
      </ModalFooter>
    </Dialog>
  );
};
