import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PersonalInjuriesContext
} from '../../../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import { FiltersBar as CommonFiltersBar } from '../../../../../../components/FiltersBar';
import { TextField } from '../../../../../../components/FormField';
import { IconComponent } from '../../../../../../components/saved-filters';
import {
  PersonalInjuryStatusesSelect
} from '../../../../../../components/medical/personal-injury-requests/PersonalInjuryStatusesSelect';
import { saveFilter } from '../../../../../../store/lastFilters';
import { transformRelationsToFilter } from './utils';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { CardContent } from './CardContent';
import { List } from './List';

const initialValues = {
  search: null,
  status: null
};

export const FiltersBar = ({ filterKey }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(PersonalInjuriesContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(filter);
    applyFilter(transformRelationsToFilter(filter));
  };

  const handleFieldChange = (name) => (event) => {
    applyFilter({ [name]: event.target.value });
    setRelationsForFilter((state) => ({ ...state, [name]: event.target.value }));
  };

  const handleStatusChange = (status) => {
    applyFilter({ status: status?.value });
    setRelationsForFilter((state) => ({ ...state, status }));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        ...lastFilters,
        ...relationsForFilter
      });
    }
  }, []);

  return (
    <CommonFiltersBar
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={(
        <IconComponent
          modalWidth={1080}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.search,
          label: filterLabels[fieldsKeysMap.search],
          field: (
            <TextField
              type="search"
              name={fieldsKeysMap.search}
              label={filterLabels[fieldsKeysMap.search]}
              placeholder="Search..."
              onChange={handleFieldChange(fieldsKeysMap.search)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.status,
          label: filterLabels[fieldsKeysMap.status],
          field: (
            <PersonalInjuryStatusesSelect
              name={fieldsKeysMap.status}
              label={filterLabels[fieldsKeysMap.status]}
              onChange={handleStatusChange}
            />
          )
        }
      ]}
    />
  );
};
