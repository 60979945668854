import { useContext, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Label } from '../../../../../../components/Label';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { statusColorsMap } from '../../../../../../components/support/statusesOptions';
import { UserLink } from '../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../components/saved-filters';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { Loader } from '../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow noWrap flexBasis={columnsWidths.search}>
        {params?.search || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        <Label label={params?.status?.label} color={statusColorsMap[params?.status?.value]} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.office}>
        <OfficeLink office={params?.office_id} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.issuer}>
        <UserLink user={params?.issuer_id} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.assignee}>
        <UserLink user={params?.assignee_id} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.creator}>
        <UserLink user={params?.creator_id} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.category}>
        {params?.category?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <ListRowCellActions>
          <IconButton
            edge="end"
            color="error"
            title="Delete filter"
            onClick={stopPropagation(removeSavedFilter)}
          >
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
