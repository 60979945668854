/* eslint-disable max-len */

import { Typography, Box } from '@material-ui/core';
import { ServicePageLayout } from '../shared/ServicePageLayout';
import { useServicePageStyles } from '../shared/styles';

export const TermsConditions = () => {
  const classes = useServicePageStyles();

  return (
    <ServicePageLayout title="Terms and Conditions">
      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          1. Introduction
        </Typography>

        <Typography paragraph>
          Welcome to CaseArk.com ("Website"), a platform owned and operated by <strong>ITTECHADMIN</strong> ("Company", "we", "us", "our"). By accessing or using CaseArk, you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, you must not use our services.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          2. Definitions
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>"User"</strong> refers to anyone who accesses or uses CaseArk.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>"Services"</strong> refer to all features, tools, and functionalities provided by CaseArk.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>"Account"</strong> refers to the registered access credentials used to log into CaseArk.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          3. Eligibility
        </Typography>

        <Typography paragraph>
          To use CaseArk, you must be at least 18 years old and legally capable of entering into a binding contract. If you are using the platform on behalf of an entity, you represent that you have the authority to bind that entity to these Terms.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          4. Use of Services
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users must provide accurate and complete information when registering an account.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            You are responsible for maintaining the confidentiality of your account credentials.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Any misuse, unauthorized access, or violation of laws while using CaseArk is strictly prohibited.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We reserve the right to modify, suspend, or terminate access to CaseArk at our discretion.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          5. Payments and Subscription
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Certain features of CaseArk may require a subscription or one-time payment.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Fees are non-refundable unless otherwise stated in a separate agreement.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Failure to make timely payments may result in account suspension.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Automatic Renewal Disclosure (For Subscription-Based Features):</strong> If you subscribe to <strong>paid services</strong>, your subscription <strong>automatically renews</strong> unless canceled <strong>before the renewal date</strong>. In compliance with <strong>California Automatic Renewal Law</strong>, you will receive a <strong>clear reminder</strong> before renewal and instructions to cancel.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          6. Data Privacy (HIPAA, CMIA, CCPA & CalOPPA Compliance)
        </Typography>

        <Typography paragraph>
          Your rights under the <strong>California Consumer Privacy Act (CCPA)</strong>:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Right to Know:</strong> You can request information on how we collect, use, and share your personal data.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Right to Delete:</strong> You may request that we delete your personal information, subject to exceptions.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Right to Opt-Out:</strong> We do not sell personal data, but California users can request restrictions on data processing.
          </Typography>
        </div>

        <Box mt={2}>
          <Typography paragraph>
            <strong>HIPAA and CMIA Compliance (Medical Data Protection)</strong>
          </Typography>
        </Box>

        <Typography paragraph>
          If you use CaseArk to process or store medical information, you acknowledge that:
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            CaseArk follows the <strong>Health Insurance Portability and Accountability Act (HIPAA)</strong> and the <strong>California Confidentiality of Medical Information Act (CMIA)</strong> to protect patient records.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Protected Health Information (PHI)</strong> is stored securely with encryption, access controls, and audit logs.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We do not share, sell, or disclose medical records without explicit <strong>patient authorization or legal requirement</strong>.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users must ensure that their use of CaseArk complies with HIPAA privacy and security rules.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            If CaseArk is used by <strong>medical providers or insurance companies</strong>, a <strong>Business Associate Agreement (BAA)</strong> may be required before sharing PHI.
          </Typography>
        </div>

        <Box mt={2}>
          <Typography paragraph>
            We comply with the <strong>California Online Privacy Protection Act (CalOPPA)</strong> and will respond to <strong>Do Not Track</strong> requests as required. Your use of CaseArk is also governed by our Privacy Policy, which outlines how we collect, use, and protect your data. By using CaseArk, you consent to our data practices.
          </Typography>
        </Box>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          7. Intellectual Property
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            All content, logos, and trademarks on CaseArk are owned by <strong>ITTECHADMIN</strong>.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users may not copy, modify, distribute, or sell any proprietary content without our express permission.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          8. User-Submitted Content
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users are responsible for any data, content, or materials they submit to CaseArk.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We do not endorse or guarantee the accuracy of user-generated content.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          9. Limitation of Liability
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            CaseArk is provided on an "as-is" and "as-available" basis.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>ITTECHADMIN is not liable</strong> for any indirect, incidental, or consequential damages arising from the use of CaseArk.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We do not guarantee uninterrupted or error-free operation of our platform.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>Medical Disclaimer:</strong> CaseArk is not a medical provider and does not offer medical advice, diagnosis, or treatment. Any medical information stored on the platform is the sole responsibility of the user or provider who entered it.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            <strong>General Disclaimer:</strong> ITTECHADMIN and CaseArk are not responsible for any data loss, unauthorized access, or security breaches affecting user data. Users assume full responsibility for any risks associated with data storage and transmission.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          10. Termination
        </Typography>

        <div className={classes.bulletItem}>
          <Typography component="div">
            We reserve the right to terminate or suspend user access for violations of these Terms.
          </Typography>
        </div>

        <div className={classes.bulletItem}>
          <Typography component="div">
            Users may close their accounts at any time, but outstanding obligations (such as payments) remain enforceable.
          </Typography>
        </div>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          11. Governing Law (California Users)
        </Typography>

        <Typography paragraph>
          For users residing in California, these Terms are governed by the laws of California, and any disputes shall be resolved in California courts or through arbitration.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          12. California Consumer Complaint Assistance
        </Typography>

        <Typography paragraph>
          In compliance with California Business & Professions Code § 17538, California residents may contact:
        </Typography>

        <Box ml={3}>
          <Typography paragraph>
            California Department of Consumer Affairs (DCA)<br />
            Consumer Information Center: 1625 North Market Blvd., Suite N-112, Sacramento, CA 95834<br />
            Phone: (800) 952-5210
          </Typography>
        </Box>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          13. Amendments
        </Typography>

        <Typography paragraph>
          We may update these Terms from time to time. Users will be notified of significant changes, and continued use of CaseArk constitutes acceptance of the updated Terms.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle} component="h2">
          14. Contact Information
        </Typography>

        <Typography paragraph>
          For any questions regarding these Terms, please contact us at:
        </Typography>

        <Typography className={classes.companyInfo}>
          <strong>ITTECHADMIN</strong><br />
          Email: info@ittechadmin.com<br />
          Phone: (818) 647-1937<br />
          Address: 4812 Topanga Can Blvd, Woodland Hills, CA 91364
        </Typography>
      </section>
    </ServicePageLayout>
  );
};
