import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import {
  EditPersonalInjuryModal
} from '../../../../../../../components/medical/personal-injury-requests/EditPersonalInjuryModal';
import {
  PersonalInjuriesContext
} from '../../../../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import {
  ViewPersonalInjuryModal
} from '../../../../../../../components/medical/personal-injury-requests/ViewPersonalInjuryModal';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import * as piRequestsApi from '../../../../../../../api/personal-injury-requests';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';

export const Menu = ({ piRequest, onClose = () => {} }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { updatePersonalInjury, deletePersonalInjury } = useContext(PersonalInjuriesContext);

  const editPersonalInjury = () => {
    openModal(EditPersonalInjuryModal, {
      payload: {
        initialValues: piRequest
      },

      onModalResolved: (data) => {
        updatePersonalInjury(data);
      }
    });
  };

  const handlePersonalInjuryDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        piRequestsApi.deletePersonalInjury(piRequest.id).then(() => {
          deletePersonalInjury(piRequest.id);
          enqueueSnackbar('Ticket successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Ticket not deleted', { variant: 'error' });
        });
      }
    });
  };

  const openPreviewModal = () => {
    openModal(ViewPersonalInjuryModal, {
      payload: {
        piRequest: piRequest
      }
    });
  };

  return (
    <List onClick={onClose}>
      <MenuItem onClick={openPreviewModal}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <>
          <MenuItem onClick={editPersonalInjury}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>

          {!hasRole(rolesMap.patient) &&
            <MenuItem onClick={handlePersonalInjuryDelete}>
              <ListItemIcon>
                <DeleteIcon color="error" fontSize="inherit"/>
              </ListItemIcon>

              <ListItemText primary="Delete" />
            </MenuItem>
          }
        </>
      }
    </List>
  );
};
