import { useContext } from 'react';
import { Checkbox, Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { TrashContext } from '../../TrashProvider';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  const { selectedFilesIDs, allFilesIsSelected, toggleAllFilesSelection } = useContext(TrashContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell>
          <Checkbox
            edge="start"
            indeterminate={!!selectedFilesIDs.length && !allFilesIsSelected()}
            checked={allFilesIsSelected()}
            onClick={toggleAllFilesSelection}
          />
        </ListRowCell>

        <ListRowCell grow={true} flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.size}>
          Size
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Deleted at
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
