export const filterFieldsMap = {
  originalFilename: 'original_filename',
  tags: 'tags',
  folders: 'folders',
  updatedAfter: 'updated_after',
  updatedBefore: 'updated_before',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.originalFilename]: 'File name',
  [filterFieldsMap.tags]: 'Tags',
  [filterFieldsMap.folders]: 'Folders',
  [filterFieldsMap.updatedAfter]: 'Updated After',
  [filterFieldsMap.updatedBefore]: 'Updated Before',
  [filterFieldsMap.orderBy]: 'Order By'
};
