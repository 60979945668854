import { useEffect } from 'react';
import moment from 'moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Label } from '../../../../../../../../components/Label';
import { Loader } from '../../../../../../../../components/Loader';
import {
  usePersonalInjury
} from '../../../../../../../../components/medical/personal-injury-requests/usePersonalInjury';
import { statusColorsMap, statusLabelsMap } from '../../../../../../../../components/support/statusesOptions';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Popper } from '../../../../../../../../components/Popper';
import { getUserFullName } from '../../../../../../../../helpers/users';
import { Menu } from '../../Menu';
import { columnsWidths } from '../listConfig';

export const Row = ({ item: piRequest, isLoaded, recalculateHeight }) => {
  const { openPersonalInjuryPreviewModal } = usePersonalInjury(piRequest);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={stopPropagation(openPersonalInjuryPreviewModal)}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        {getUserFullName(piRequest)}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        <Label label={statusLabelsMap[piRequest?.status]} color={statusColorsMap[piRequest?.status]} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.creationDate}>
        {moment.utc(piRequest?.created_at).local().format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.latestUpdates}>
        {moment.utc(piRequest?.updated_at).local().format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <ListRowCellActions>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton
                  edge="end"
                  buttonRef={anchorRef}
                  onClick={stopPropagation(handleToggle)}
                >
                  <MoreVertIcon/>
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <Menu piRequest={piRequest} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
