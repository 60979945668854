import { Formik } from 'formik';
import { pick } from 'lodash';
import moment from 'moment';
import { Dialog, useTheme, useMediaQuery, Typography, Grid } from '@material-ui/core';
import { getUserFullName } from '../../../../helpers/users';
import { Label } from '../../../Label';
import { LinkableText } from '../../../LinkableText';
import { ModalHeader, ModalBody, ModalContainer } from '../../../Modal';
import { OfficeLink } from '../../../OfficeLink';
import { UserLink } from '../../../UserLink';
import { statusColorsMap, statusLabelsMap } from '../statusesOptions';

export const ViewPersonalInjuryModal = ({
  DialogProps,
  payload: {
    piRequest
  },
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  return (
    <Formik
      initialValues={pick(piRequest, [ 'id', 'files' ])}
      onSubmit={null}
    >
      <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
        <ModalContainer noValidate>
          <ModalHeader onClose={handleModalReject}>
            {getUserFullName(piRequest)}
          </ModalHeader>

          <ModalBody>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Creation date</Typography>
                <Typography>{moment.utc(piRequest?.created_at).local().format('L LT')}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Latest updates</Typography>
                <Typography>{moment.utc(piRequest?.changed_at).local().format('L LT')}</Typography>
                <UserLink size="xs" user={piRequest?.modifier} />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Status</Typography>
                <br />
                <Label label={statusLabelsMap[piRequest?.status]} color={statusColorsMap[piRequest?.status]} />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Office</Typography>
                <OfficeLink office={piRequest.office} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Description</Typography>

                <LinkableText target="_blank" color="inherit">
                  <Typography>{piRequest.description}</Typography>
                </LinkableText>
              </Grid>
            </Grid>
          </ModalBody>
        </ModalContainer>
      </Dialog>
    </Formik>
  );
};
