import { useContext, useRef } from 'react';
import { List as MuiList, Box, Typography } from '@material-ui/core';
import {
  PersonalInjuriesContext
} from '../../../../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import { ScrollbarsCustom } from '../../../../../../../components/ScrollbarsCustom';
import { Sticky } from '../../../../../../../components/Sticky';
import { Loader } from '../../../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = () => {
  const {
    personalInjuries,
    isFetched,
    isFetching,
    loadNextPage,
    meta
  } = useContext(PersonalInjuriesContext);
  const scrollElementRef = useRef();

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} style={{ flexGrow: 1, height: '100%' }}>
      <Box py={2}>
        <Loader loading={!isFetched} p={2} render={
          () => !personalInjuries?.length ? (
            <Box p={3}>
              <Typography align="center">No requests found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={personalInjuries}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
