import { every, isEmpty } from 'lodash';
import { api } from '../api';
import { tagsActionsMap, tagsChangingMethodsMap } from '../files/tags';

export const transformAttachment = (media) => {
  if (every(media.previews, isEmpty)) {
    media.previews = null;
  }

  return media;
};

const transformAttachments = (attach) => {
  return attach?.map(transformAttachment)?.filter((item) => !isEmpty(item));
};

const transformMessage = (message) => {
  if (message?.attachments?.length) {
    message.attachments = transformAttachments(message?.attachments);
  }

  return message;
};

export const getEmailConfigList = (config = {}) => {
  return api.get('/email-config', config)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const connectEmailProvider = (data) => {
  return api.post('/email-config/oauth/connect', data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const createEmailConfig = (data) => {
  return api.post('/email-config', data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const editEmailConfig = (data) => {
  return api.put(`/email-config/${data.id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const getEmailConfig = (configId) => {
  return api.get(`/email-config/${configId}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteEmailConfig = (configId) => {
  return api.delete(`/email-config/${configId}`)
    .then(({ data: { message } }) => message)
    .catch(({ data: { message } }) => { throw message; });
};

export const getEmailFolders = (config_id, config) => {
  return api.get('/email-imap/folders', { params: { config_id }, ...config })
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const getMessages = (config) => {
  return api.get('/email-imap/messages', config)
    .then(({ data }) => ({
      ...data,

      data: data.data?.map(transformMessage) || []
    }))
    .catch((error) => { throw error.data; });
};

export const fetchMessage = (config) => {
  return api.get('/email-imap/message', config)
    .then(({ data }) => transformMessage(data))
    .catch((error) => { throw error.data; });
};

export const deleteMessages = (data) => {
  return api.delete('/email-imap/message', { data })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const saveMessages = (data) => {
  return api.post('/user-emails', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const sendEmail = (data) => {
  return api.post('/email-imap/send', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const answerEmail = (data) => {
  return api.post('/email-imap/answer', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const forwardEmail = (data) => {
  return api.post('/email-imap/forward', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const saveEmailToDrafts = (data) => {
  return api.post('/email-imap/save-drafts', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchSavedMessages = (config) => {
  return api.get('/user-emails', config)
    .then(({ data: { data, ...meta } }) => ({
      data: data.map(transformMessage),
      ...meta
    }))
    .catch((error) => { throw error.data; });
};

export const deleteSavedMessages = (IDs) => {
  return api.delete('/user-emails', { params: { emails_id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const assignEmails = (data) => {
  return api.post('/user-emails/assign', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const changeTags = (actionType, data) => {
  const method = tagsChangingMethodsMap[actionType];
  const isDelete = method === tagsChangingMethodsMap[tagsActionsMap.delete];

  return api[method]('/user-emails-tags', isDelete ? { data } : data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const downloadPdf = (config) => {
  return api.get('/email-imap/pdf', {
    ...config,
    responseType: 'blob'
  });
};

export const downloadHTml = (config) => {
  return api.get('/email-imap/html', {
    ...config,
    responseType: 'blob'
  });
};

export const downloadAttachment = (config) => {
  return api.get('/email-imap/attachment', {
    ...config,
    responseType: 'blob'
  });
};

export const downloadAllAttachment = (config) => {
  return api.get('/email-imap/attachments', {
    ...config,
    responseType: 'blob'
  });
};

export const addAttachmentToCase = (config) => {
  return api.post('/email-imap/attachment-case', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const addAttachmentToUser = (config) => {
  return api.post('/email-imap/attachment-user', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const markMessage = (data) => {
  return api.post('/email-imap/set-mark', data);
};

export const getCountUnseen = (config_id, config) => {
  return api.get('/email-imap/counters', { params: { config_id }, ...config })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const replyEvent = (data) => {
  return api.post('/email-imap/event', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
