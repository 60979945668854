import { useContext } from 'react';
import filesize from 'filesize';
import moment from 'moment';
import { useModal } from 'react-modal-hook';
import { Checkbox, IconButton, Typography, Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { Popper } from '../../../../../../components/Popper';
import { Tag } from '../../../../../../components/Tag';
import { FilePreviewModal, FilePreviewIcon } from '../../../../files-common';
import { FileMenu } from '../../FileMenu';
import { TrashContext } from '../../TrashProvider';
import { columnsWidths } from '../List';
import { getFileMenuOptions } from './getFileMenuOptions';

export const Row = ({ item: file = {}, isLoaded }) => {
  const trashContext = useContext(TrashContext);
  const isSelected = trashContext.selectedFilesIDs.indexOf(file.id) !== -1;
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(trashContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files: trashContext.files,
        isFetched: trashContext.isFetched,
        isFetching: trashContext.isFetching,
        filter: trashContext.filter,
        onNeedNextPage: trashContext.loadNextPage
      }}
    />
  ), [ file, trashContext.files ]);

  const handleItemSelection = () => {
    trashContext.toggleFileSelection(file.id);
  };

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow selected={isSelected}>
      <ListRowCell>
        <Checkbox edge="start" checked={isSelected} onClick={handleItemSelection} />
      </ListRowCell>

      <ListRowCell grow actions flexBasis={columnsWidths.name}>
        <FilePreviewIcon file={file} onClick={openFilesPreview} />

        <Box overflow="hidden">
          <Typography noWrap>
            {file.original_filename}
          </Typography>

          <Box display="flex" flexWrap="wrap">
            {file.tags && file.tags.map((tag) => (
              <Box clone key={tag.id} m={0.25}>
                <Tag tag={tag} />
              </Box>
            ))}
          </Box>
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.size}>
        {filesize(file.size)}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(file.deleted_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={handleToggle}>
              <MoreVertIcon />
            </IconButton>
          )}
        >
          {({ handleClose }) => (
            <FileMenu file={file} openFilePreview={openFilesPreview} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
