import { makeStyles } from '@material-ui/core';

export const useServicePageStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  effectiveDate: {
    marginBottom: theme.spacing(3)
  },
  section: {
    marginTop: theme.spacing(2)
  },
  sectionTitle: {
    fontWeight: 'bold'
  },
  bulletItem: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '&:before': {
      content: '"• "',
      marginRight: theme.spacing(1),
      fontWeight: 'bold'
    }
  },
  companyInfo: {
    marginTop: theme.spacing(1)
  },
  backLink: {
    color: theme.palette.common.white
  }
}));
