import { useContext, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Label } from '../../../../../../../../components/Label';
import { statusColorsMap } from '../../../../../../../../components/support/statusesOptions';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow noWrap flexBasis={columnsWidths.search}>
        {params?.search || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        <Label label={params?.status?.label} color={statusColorsMap[params?.status?.value]} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <ListRowCellActions>
          <IconButton
            edge="end"
            color="error"
            title="Delete filter"
            onClick={stopPropagation(removeSavedFilter)}
          >
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
