import { useRef, useState } from 'react';
import { makeStyles, Box, Tab, Tabs } from '@material-ui/core';
import {
  PersonalInjuriesProvider
} from '../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import { fieldsKeysMap } from '../../../../components/users/MedicalInfo';
import { Page, PageHeader } from '../../../../components/Page';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { orderByMap } from '../../ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar/orderByOptions';
import { MedicalFormsProvider } from '../../ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { DeletedMedicalForms } from '../DeletedMedicalForms';
import { DeletedMedicalFormsProvider } from '../DeletedMedicalForms/DeletedMedicalFormsProvider';
import { PiRequests } from './PiRequests';
import { UnconfirmedForms } from './UnconfirmedForms';
import { ConfirmedForms } from './ConfirmedForms';
import { styles } from './styles';

const tabsMap = {
  unconfirmed: 'unconfirmed',
  confirmed: 'confirmed',
  deleted: 'deleted',
  piRequests: 'piRequests'
};

const hiddenUnconfirmedFormsFields = [
  fieldsKeysMap.patients,
  fieldsKeysMap.users,
  fieldsKeysMap.formType,
  fieldsKeysMap.insurance
];

const hiddenConfirmedFormsFields = [
  fieldsKeysMap.search
];

const useStyles = makeStyles(styles);

export const MainContent = () => {
  const classes = useStyles();
  const [ activeTab, setActiveTab ] = useState(tabsMap.confirmed);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const initialState = {
    filter: {
      medical_id: null,
      order_by: orderByMap.updated_at
    },
    medicalInfoPathname: ''
  };

  const handleTabIndexChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Page
      ref={rootRef}
      square={false}
      elevation={0}
      component={Box}
      pt={0.5}
    >
      <PageHeader disablePaddings>
        <Tabs
          variant="scrollable"
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabIndexChange}
          className={classes.tabs}
        >
          <Tab value={tabsMap.confirmed} label="Сonfirmed" />
          <Tab value={tabsMap.unconfirmed} label="Unconfirmed" />
          <Tab value={tabsMap.deleted} label="Deleted" />
          <Tab value={tabsMap.piRequests} label="PI Requests" />
        </Tabs>
      </PageHeader>

      {(activeTab === tabsMap.unconfirmed) &&
        <MedicalFormsProvider initialState={initialState}>
          <UnconfirmedForms
            width={width}
            filterKey={filtersKeysMap.patient_medical_forms_unconfirmed}
            hiddenFilterFields={hiddenUnconfirmedFormsFields}
          />
        </MedicalFormsProvider>
      }

      {(activeTab === tabsMap.confirmed) &&
        <MedicalFormsProvider initialState={initialState}>
          <ConfirmedForms
            width={width}
            filterKey={filtersKeysMap.patient_medical_forms_confirmed}
            hiddenFilterFields={hiddenConfirmedFormsFields}
          />
        </MedicalFormsProvider>
      }

      {(activeTab === tabsMap.deleted) &&
        <DeletedMedicalFormsProvider>
          <DeletedMedicalForms
            width={width}
            filterKey={filtersKeysMap.patient_medical_forms_confirmed}
            hiddenFilterFields={hiddenConfirmedFormsFields}
          />
        </DeletedMedicalFormsProvider>
      }

      {(activeTab === tabsMap.piRequests) &&
        <PersonalInjuriesProvider>
          <PiRequests
            width={width}
            filterKey={filtersKeysMap.personal_injury_requests}
          />
        </PersonalInjuriesProvider>
      }
    </Page>
  );
};
