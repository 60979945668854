import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().max(254).required(),
  title: Yup.string().nullable().max(254).required(),
  users: Yup.array().of(Yup.mixed().required()).nullable(),
  show_all_users: Yup.boolean().nullable().default(false),
  description: Yup.string().max(6000).nullable(),
  timezone: Yup.string().nullable(),
  recurring_rule: Yup.string().nullable(),
  office_id: Yup.mixed(),
  type_id: Yup.mixed().nullable(),
  is_duration_corresponds_to_type: Yup.boolean().nullable(),
  is_public: Yup.boolean().nullable()
});
