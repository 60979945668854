import { useContext, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { useResizeObserver } from '../../../../helpers/hooks';
import { FilesFilterContextProvider } from '../../files-common';
import { ActionsBar } from './ActionsBar';
import { Grid } from './Grid';
import { List } from './List';
import { styles } from './styles';
import { TrashProvider } from './TrashProvider';

const useStyles = makeStyles(styles);

export const Trash = ({ ownerType, ownerID }) => {
  const classes = useStyles();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = width <= theme.breakpoints.values.mobileSm;

  useEffect(() => {
    setViewVariant(viewVariantsMap.list);
  }, []);

  return (
    <FilesFilterContextProvider>
      <TrashProvider ownerType={ownerType} ownerID={ownerID}>
        <div ref={rootRef} className={classes.root}>
          <ActionsBar />

          <div className={classes.filesWindow}>
            <div className={classes.filesWindow__main}>
              {!isMobile ? (
                (viewVariant === viewVariantsMap.list)
                  ? <List />
                  : (viewVariant === viewVariantsMap.grid) ? <Grid /> : null
              ) : <Grid />}
            </div>
          </div>
        </div>
      </TrashProvider>
    </FilesFilterContextProvider>
  );
};
