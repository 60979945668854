import { createContext, useRef, useEffect, useReducer } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../api';
import { usePrevious } from '../../../../helpers/hooks';
import * as personalInjuriesApi from '../../../../api/personal-injury-requests';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const PersonalInjuriesContext = createContext();

export const PersonalInjuriesProvider = ({
  filter: filterProp = {},
  children
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    personalInjuries
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchPersonalInjuries = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_PERSONAL_INJURIES_REQUEST });

    personalInjuriesApi.fetchPersonalInjuries({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_PERSONAL_INJURIES_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchPersonalInjuries({ page: filter.page + 1 });
    }
  };

  const resetPersonalInjuries = (newFilter) => {
    dispatch({ type: types.RESET_PERSONAL_INJURIES, payload: newFilter });

    fetchPersonalInjuries({ page: 1, ...newFilter });
  };

  const addTicket = (personalInjury) => {
    dispatch({ type: types.ADD_PERSONAL_INJURY, payload: personalInjury });
  };

  const updatePersonalInjury = (personalInjury) => {
    dispatch({ type: types.UPDATE_PERSONAL_INJURY, payload: personalInjury });
  };

  const deletePersonalInjury = (personalInjuryId) => {
    dispatch({ type: types.DELETE_PERSONAL_INJURY, payload: personalInjuryId });
  };

  const providerValue = {
    isFetched,
    defaultFilter: filterProp,
    isFetching,
    personalInjuries,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetPersonalInjuries,
    fetchPersonalInjuries,
    loadNextPage,
    applyFilter,
    addTicket,
    updatePersonalInjury,
    deletePersonalInjury
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetPersonalInjuries(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <PersonalInjuriesContext.Provider value={providerValue}>
      {children}
    </PersonalInjuriesContext.Provider>
  );
};
