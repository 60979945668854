import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  title: Yup.string().nullable().max(254).required(),
  users: Yup.array().of(Yup.mixed().required()).nullable(),
  show_all_users: Yup.boolean().nullable().default(false),
  description: Yup.string().max(6000).nullable(),
  started_at: Yup.date().format('X').nullable().required(),
  finished_at: Yup.date().format('X').nullable().required(),
  timezone: Yup.string().nullable(),
  recurring_rule: Yup.string().nullable(),
  office_id: Yup.mixed(),
  type_id: Yup.mixed().nullable(),
  parent_type: Yup.string().nullable(),
  parent_id: Yup.mixed().nullable().when('parent_type', {
    is: (value) => value,
    then: Yup.mixed().required()
  })
});
