import { useContext } from 'react';
import { SvgIcon, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import RestoreIcon from '@material-ui/icons/Restore';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { Button } from '../../../../../components/Button';
import { IconButton } from '../../../../../components/IconButton';
import { AppBar } from '../../../../../components/AppBar';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { TrashContext } from '../TrashProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = () => {
  const classes = useStyles();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { filter, selectedFilesIDs, deleteFilesFromSelected, ...trashContext } = useContext(TrashContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const handleDeleteSelectedFiles = () => {
    trashContext.deleteFiles(selectedFilesIDs).then((deletedIDs) => {
      deleteFilesFromSelected(deletedIDs);
    });
  };

  const handleRestoreSelectedFiles = () => {
    trashContext.restoreFiles(selectedFilesIDs).then((restoredFileIDs) => {
      deleteFilesFromSelected(restoredFileIDs);
    });
  };

  return (
    <AppBar ToolbarProps={{ className: classes.root }}>
      <div className={classes.grow}>
        <Button
          size={isTablet ? 'small' : 'medium'}
          variant="contained"
          color="error"
          disabled={!trashContext.files.length}
          startIcon={<DeleteForeverOutlinedIcon />}
          onClick={trashContext.emptyTrash}
        >
          Empty trash
        </Button>
      </div>

      {!!selectedFilesIDs.length &&
        <div className={classes.multiActions}>
          <SelectedItemsCount total={filter.total} length={selectedFilesIDs.length} />

          <VerticalDivider verticalGutters={1} horizontalGutters={2} />

          <IconButton
            edge="start"
            title="Delete selected files"
            onClick={handleDeleteSelectedFiles}
          >
            <SvgIcon><Icon path={mdiTrashCanOutline} /></SvgIcon>
          </IconButton>

          <IconButton
            edge="end"
            title="Restore selected files"
            onClick={handleRestoreSelectedFiles}
          >
            <RestoreIcon />
          </IconButton>

          <VerticalDivider verticalGutters={1} horizontalGutters={2} />
        </div>
      }

      {!isTablet && (
        <>
          <IconButton
            edge="start"
            color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
            onClick={toggleViewVariant(viewVariantsMap.list)}
          >
            <ViewListIcon />
          </IconButton>

          <IconButton
            edge="end"
            color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
            onClick={toggleViewVariant(viewVariantsMap.grid)}
          >
            <ViewModuleIcon />
          </IconButton>
        </>
      )}
    </AppBar>
  );
};
