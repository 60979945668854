import { Formik } from 'formik';
import { Dialog, Button, FormControl, FormControlLabel } from '@material-ui/core';
import { uniqBy } from 'lodash';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../Modal';
import { Loader } from '../../../Loader';
import { RadioGroup, Radio } from '../../../FormField';

const settingVariantsMap = {
  leaveCurrent: 'leaveCurrent',
  replace: 'replace',
  add: 'add'
};

export const SetScheduleEventUsersModal = ({
  DialogProps,
  payload: {
    currentUsers = [],
    newUsers = []
  },
  handleModalResolve,
  handleModalReject
}) => {
  const handleSubmit = ({ variant }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (variant === settingVariantsMap.replace) {
      handleModalResolve(newUsers);
    } else if (variant === settingVariantsMap.add) {
      handleModalResolve(uniqBy((currentUsers || []).concat(newUsers || []), 'id'));
    } else {
      handleModalReject();
    }
  };

  return (
    <Formik
      initialValues={{
        variant: settingVariantsMap.leaveCurrent
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog disableEnforceFocus maxWidth="xs" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Update event users
            </ModalHeader>

            <ModalBody>
              <FormControl fullWidth component="fieldset" margin="dense">
                <RadioGroup name="variant">
                  <FormControlLabel
                    value={settingVariantsMap.leaveCurrent}
                    label="Leave current users"
                    control={<Radio name="variant" color="primary" />}
                  />

                  <FormControlLabel
                    value={settingVariantsMap.replace}
                    label="Replace"
                    control={<Radio name="variant" color="primary" />}
                  />

                  <FormControlLabel
                    value={settingVariantsMap.add}
                    label="Add to current"
                    control={<Radio name="variant" color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Apply
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
