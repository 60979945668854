import { useRef, useContext, useEffect } from 'react';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Route } from '../../../../../components/router';
import { Loader } from '../../../../../components/Loader';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { FilesContext, FilesContextProvider, FilesFilterContextProvider } from '../../../files-common';
import { RecentlyOpened } from '../RecentlyOpened';
import { Card } from './Card';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Grid = () => {
  const {
    files,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    ...filesContext
  } = useContext(FilesContext);
  const classes = useStyles();
  const scrollElementRef = useRef();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: scrollElementRef });
  const theme = useTheme();
  const isMobile = width <= theme.breakpoints.values.mobileLg;

  useEffect(() => {
    filesContext.resetFiles();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <div ref={rootRef} className={classes.root}>
        {!isMobile && (
          <Route exact path="/files">
            <FilesFilterContextProvider>
              <FilesContextProvider
                initialState={{
                  filter: {
                    only_opened: 1,
                    order_by: 'opened_at'
                  }
                }}
              >
                <RecentlyOpened />
              </FilesContextProvider>
            </FilesFilterContextProvider>

            <Typography variant="h3" gutterBottom>Files</Typography>
          </Route>
        )}

        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No files found</Typography>
          ) : (
            <>
              <Route exact path="/files">
                <Typography variant="h3" gutterBottom>Files</Typography>
              </Route>

              <InfiniteGridLoader
                items={files}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                CellComponent={Card}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                cellWidth={200}
                cellHeight={200}
              />
            </>
          )}
        />
      </div>
    </CustomScrollbars>
  );
};
