import { ListRow, ListRowCell } from '../../../../ListRow';
import { filterFieldsMap } from '../../filterFieldsMap';
import { columnsWidths } from '../List';

export const ListHeader = ({ hiddenColumns }) => {
  return (
    <ListRow>
      <ListRowCell flexBasis={columnsWidths.title}>
        Title
      </ListRowCell>

      {!hiddenColumns.includes(filterFieldsMap.cases) && (
        <ListRowCell flexBasis={columnsWidths.cases}>
          Cases
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.tasks}>
        Tasks
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.offices}>
        Offices
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
