import { useContext, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { saveFilter } from '../../../../../store/lastFilters';
import { FilesFilterContext, orderByOptions } from '../../../files-common';
import { initialState } from '../../../files-common';
import {
  TextField,
  TasksSelect,
  KeyboardDatePicker
} from '../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { OrderBy } from '../../../../../components/FormField';
import { IconComponent } from '../../../../../components/saved-filters';
import { TagsSelect } from '../../../../../components/Tags/TagsSelect';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { CardContent } from './CardContent';
import { List } from './List';
import { styles } from './styles';
import { transformRelationsForFilterToOptions } from './utils';

const initialValues = {
  ...initialState.filter,

  order_by: orderByOptions.find(({ value }) => value === initialState.filter.order_by)
};

const MODAL_WIDTH = 900;

const useStyles = makeStyles(styles);

export const FiltersBar = ({ filterKey = '', hiddenFields = [] }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { filter, applyFilter } = useContext(FilesFilterContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleTagsChange = (tags) => {
    applyFilter({ ...filter, tags: tags?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, tags }));
  };

  const handleFoldersChange = (folders) => {
    applyFilter({ ...filter, folders: folders?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, folders }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
  };

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data }));
  };

  const handleTasksChange = (tasks) => {
    applyFilter({ tasks: tasks?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, tasks }));
  };

  const handleCasesChange = (cases) => {
    applyFilter({ cases: cases?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, cases }));
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(filter));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        ...relationsForFilter,
        ...lastFilters
      });
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      className={classes.root}
      ToolbarProps={{ className: classes.toolbar }}
      initialValues={initialValues}
      hiddenFields={hiddenFields}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.originalFilename,
          label: filterFieldsLabels[filterFieldsMap.originalFilename],
          field: <TextField
            name="original_filename"
            label="Search"
            placeholder="Search..."
            onChange={handleFieldChange('original_filename')}
          />
        },
        {
          fieldKey: filterFieldsMap.updatedAfter,
          label: filterFieldsLabels[filterFieldsMap.updatedAfter],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              disableFuture
              name="updated_after"
              label="Updated after"
              onChange={handleDatePickerChange('updated_after')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.updatedBefore,
          label: filterFieldsLabels[filterFieldsMap.updatedBefore],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              disableFuture
              name="updated_before"
              label="Updated before"
              onChange={handleDatePickerChange('updated_before')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.cases,
          label: filterFieldsLabels[filterFieldsMap.cases],
          field: <CasesSelect
            multiple
            name="cases"
            label="Cases"
            placeholder="Search case by name..."
            onChange={handleCasesChange}
          />
        },
        {
          fieldKey: filterFieldsMap.tasks,
          label: filterFieldsLabels[filterFieldsMap.tasks],
          field: <TasksSelect
            multiple
            name="tasks"
            label="Tasks"
            placeholder="Search task by name..."
            params={{ cases: filter.cases }}
            onChange={handleTasksChange}
          />
        },
        {
          fieldKey: filterFieldsMap.tags,
          label: filterFieldsLabels[filterFieldsMap.tags],
          field: <TagsSelect
            multiple
            filterSelectedOptions
            name="tags"
            label="Select tags"
            placeholder="Select tags..."
            onChange={handleTagsChange}
          />
        },
        {
          fieldKey: filterFieldsMap.folders,
          label: filterFieldsLabels[filterFieldsMap.folders],
          field: <TagsSelect
            multiple
            filterSelectedOptions
            name="folders"
            label="Select folders"
            placeholder="Select folders..."
            params={{ is_folder: 1 }}
            onChange={handleFoldersChange}
          />
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: <OrderBy
            isSearchable={false}
            name="order_by"
            options={orderByOptions}
            orderDirection={filter.order_direction}
            onOptionChange={handleSelectChange('order_by')}
            onOrderDirectionChange={toggleOrderDirection}
          />
        }
      ]}
    />
  );
};
