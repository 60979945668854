import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import { useSelector } from 'react-redux';
import * as medicalProvidersApi from '../../../api/profile';
import { CreateCustomerModal } from '../../../app/Dashboard/Members/CreateCustomerModal';
import { getUserFullName } from '../../../helpers/users';
import * as usersApi from '../../../api/users';
import { CreateUserModal } from '../../../app/Dashboard/Contacts/Users/CreateUserModal';
import { useModal } from '../../ModalsProvider';
import { Autocomplete } from '../../FormField';
import { UserAvatar } from '../UserAvatar';

const fetchUsers = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return usersApi.fetchUsers({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const fetchGuestUsers = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return medicalProvidersApi.fetchGuestProvidersList({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const useOptionStyle = makeStyles(() => ({
  companyName: {
    lineHeight: 1
  }
}));

const Option = ({ showCompany, option }) => {
  const classes = useOptionStyle();

  return (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        <UserAvatar size="xs" user={option} />
      </Grid>

      <Grid item>
        {getUserFullName(option)}

        {showCompany && !!option?.company_name && (
          <Box display="flex" alignItems="center" color="text.secondary" mt={-0.5}>
            <HomeWorkOutlinedIcon fontSize="small" color="inherit" />&nbsp;

            <Typography variant="body2" color="inherit" className={classes.companyName}>
              {option?.company_name}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const renderOption = ({ showCompany }) => (option) => {
  return !option?.isCreatableOption ? (
    <Option showCompany={showCompany} option={option} />
  ) : (
    <>
      <Box display="flex" mr={1}>
        <AddIcon color="primary" />
      </Box>

      Add user
    </>
  );
};

export const UsersSelect = ({
  formattedValue = true,
  showCompany = false,
  multiple = false,
  isCustomerModal = false,
  params = {},
  creatablePayload,

  ...props
}) => {
  const { openModal } = useModal();
  const UserModal = isCustomerModal ? CreateCustomerModal : CreateUserModal;
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  const addUser = () => {
    return new Promise((resolve, reject) => {
      openModal(UserModal, {
        DialogProps: {
          disableRestoreFocus: true
        },
        payload: creatablePayload,
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      label="User"
      placeholder="Search user..."
      onNeedFetch={isAuthenticated
        ? fetchUsers(params)
        : fetchGuestUsers(params)
      }
      renderOption={renderOption({ showCompany })}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (
          <UserAvatar user={value} size="xs" />
        )
      })}
      getOptionLabel={getUserFullName}
      getOptionValue={(user) => formattedValue ? user?.id : user}
      getOptionSelected={(option, value) => option?.id === value?.id}
      onCreate={addUser}

      {...props}
    />
  );
};
