import moment from 'moment';
import { Box, makeStyles, Paper, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import CardAccountSvg from '../../components/icons/medical/card-account-injury.svg?react';
import { UserInfo } from '../../components/medical/forms';
import { Date } from '../../components/Date';
import { SvgIcon } from '../../components/SvgIcon';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FormHeader = ({
  form,
  type,
  activeStep,
  initialFormValue
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Paper variant="outlined" square className={classes.header}>
        <div className={classes.icon}>
          <SvgIcon fontSize="large" color="primary">
            <CardAccountSvg/>
          </SvgIcon>
        </div>

        <div className={classes.mainInfo}>
          <div className={classes.mainInfo__date}>
            <Date
              disableMoment={!form?.created_at}
              date={form?.created_at || moment.unix(moment().unix()).format('L')}
            />
          </div>

          <Typography className={classes.mainInfo__title}>
            {type}
          </Typography>
        </div>

        {!isMobile &&
          <div className={classes.userInfo}>
            <UserInfo
              activeStep={activeStep}
              initialFormValue={initialFormValue}
              form={form}
            />
          </div>
        }
      </Paper>

      {isMobile && form?.medical?.id &&
        <Box display="flex" alignItems="flex-start" justifyContent="space-between" mt={1} ml={2}>
          <UserInfo
            activeStep={activeStep}
            initialFormValue={initialFormValue}
            form={form}
          />
        </Box>
      }
    </>
  );
};
