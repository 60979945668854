import { Route, Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FullLogoSVG from '../Dashboard/Layout/Sidebar/SidebarContent/logo-full.svg?react';
import { LandingWrapper } from './LandingWrapper';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius * 2
  }
}));

const FooterLink = ({ to, children }) => (
  <Grid item>
    <Link
      underline="always"
      component={RouterLink}
      to={to}
      color="inherit"
      variant="subtitle1"
    >
      {children}
    </Link>
  </Grid>
);

export const LandingPage = () => {
  const classes = useStyles();

  return (
    <LandingWrapper>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
        <Route path="/">
          <Box width="50%" flexGrow={1}>
            <FullLogoSVG />
          </Box>

          <Box className={classes.footerContainer}>
            <Grid container spacing={1} justify="center">
              <FooterLink to="terms-conditions">Terms and Conditions</FooterLink>
              <FooterLink to="privacy-policy">Privacy Policy</FooterLink>
              <FooterLink to="cookie-policy">Cookie Policy</FooterLink>
            </Grid>
          </Box>
        </Route>
      </Box>
    </LandingWrapper>
  );
};
