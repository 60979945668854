import { useContext, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  PersonalInjuriesContext
} from '../../../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { Grid } from './Grid';
import { List } from './List';

export const MainContent = () => {
  const { viewVariant } = useContext(LayoutContext);
  const { resetPersonalInjuries } = useContext(PersonalInjuriesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    resetPersonalInjuries();
  }, []);

  return isMobile
    ? <Grid />
    : viewVariant === viewVariantsMap.list
      ? <List />
      : <Grid />;
};
