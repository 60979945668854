import { Box, Grid, Typography } from '@material-ui/core';
import { Members } from '../../../Members';
import { OfficeLink } from '../../../OfficeLink';
import { filterFieldsMap } from '../filterFieldsMap';

export const CardContent = ({ params, hiddenFields = [] }) => {
  return (
    <Grid container spacing={1}>
      {!!params?.title &&
        <>
          <Grid item xs={4}>
            <Typography>
              Title:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap title={params.title}>
              {params.title + ' '}
            </Typography>
          </Grid>
        </>
      }

      {!hiddenFields.includes(filterFieldsMap.cases) && !!params?.cases?.length && (
        <>
          <Grid item xs={4}>
            <Typography>
              Cases:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.cases?.map((caseItem, index) => (
                <Typography noWrap key={index} title={caseItem.name}>
                  {caseItem.name + ' '}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      )}

      {!!params?.tasks?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Tasks:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.tasks?.map((task, index) => (
                <Typography noWrap key={index} title={task.name}>
                  {task.name + ' '}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.users?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Users:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Members
              noWrap
              maxVisible={4}
              users={params.users || []}
            />
          </Grid>
        </>
      }

      {!!params.offices?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Offices:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {params?.offices?.map((office) => (
              <Box key={office.id} display="flex" flexDirection="column" width="100%">
                <OfficeLink
                  variant="h5"
                  office={office}
                />
              </Box>
            ))}
          </Grid>
        </>
      }
    </Grid>
  );
};
